.center-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* ou 'center' si vous voulez centrer horizontalement aussi */
    justify-content: center;
  }

.returnButton {
    color: #2d004b;
}
  
.returnButton:hover {
color: #5465ff;
transform: scale(1.1);
}

.buttonCreerProjet {
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
}

.buttonCreerProjet:hover {
    background-color: #5465ff;
    /* hover:bg-blue-700 */
}

.buttonCreerProjet:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
    /* focus:shadow-outline */
}