@media screen and (max-width: 1921px) and (min-width: 1001px) {
    h4, p, span {
        font-size: .875rem;
    }
    .roomName {
        font-weight: 600;
      }
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;
    padding-top: 30px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
}

.modalBody {
    display: flex;
    flex-direction: column;
}

.closeButton {
    position: absolute;
    top: 35px;
    right: 8px;
    background: none;
    border: none;
    cursor: pointer;
    color: red;
    font-size: 24px;
    z-index: 9999;
}

.modalTitle {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 1.8rem;
}

.roomCardImageContainerModal {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
    border-radius: 5px;
  }

  .roomCardImageContainerModalCarousel {
    position: relative;
    max-width: 700px;
    max-height: 500px;
    overflow: hidden;
    border-radius: 5px;
  }
  
  .imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: center;
  }
  
  .roomCardCarouselImg {
    width: 100%; 
    height: 100%;
    object-fit: cover;
  }
  
  .roomCardNoCarousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
.roomCardCarousel {
    width: 100%;
    height: auto;
}

/* .roomCardCarouselImg {
    width: 100%;
    height: auto;
} */

/* .carouselContainer {
    display: flex;
    justify-content: center;
    margin: auto;
} */

/* .roomCardImageContainerModalModal {
    border-radius: 5px;
    max-width: 700px;
    max-height: 500px;
} */

.detailsH2 {
    font-weight: 600;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.buildingName {
    font-weight: 500;
    font-style: italic;
}

.roomBuildingName {
    margin-bottom: 10px;
    margin-top: 30px;
}

.modalBody {
    padding-left: 20px;
    max-width: 700px;
}

.confirmButtonContainer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    /* Espace avant le bouton */
}

.buttonIncrement {
    margin-left: 10px;
    margin-right: 10px;
}

.button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #2d004b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #5465ff;
}

.totalPrice {
    margin-top: 5px;
    font-weight: 600;
    font-size: 1.1rem;
    display: flex;
    align-items: center; /* Ensure that items are vertically centered */
    flex-wrap: wrap; /* Allows children to wrap onto the next line */
}

.fees {
    text-align: right;
    font-size: 0.8rem;
    font-style: italic;
    color: grey
}

.Beds {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    margin-top: 10px;
}

.bedItem {
    display: flex;
    align-items: center; /* Align items in a row */
    margin-bottom: 10px; /* Space between each bed type */
}

.equipment {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px; /* Space after the last bed type */
}

.extraBeds {
    display: flex; 
    align-items: center; 
    /* flex-wrap: wrap;  */
    margin-bottom: 10px; 
    margin-top: 10px; 
}

.roomName {
    font-weight: 600;
  }

.numberPeople {
    font-style: italic;
}

.roomsInSuite {
    font-weight: 500;
    font-size: 1.2rem;
}

.pricingInfo {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    text-align: right;
}

.roomInfoInSuite {
    padding-left: 20px
}

.infoButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.infoPriceButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    /* margin-left: 10px; Add space between the price and the button */
}

.tooltipText {
    /*width: 75%;  Prend toute la largeur disponible pour éviter d'être à côté des boutons */
    background-color: #f8f8f8; /* Fond légèrement différent pour le distinguer */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optionnel: Ajoute un peu d'ombre pour le style */
    font-size: 0.8rem;
    margin-bottom: 10px;
}

.errorMessage {
    color: red;
    margin-top: 10px;
  }

/* Responsive adjustments with media queries */
@media (max-width: 415px) {
    .roomCardImageContainerModal {
        max-width: 70dvw;
        min-width: 70dvw;
    }
    .modalTitle {
        font-size: 1.5rem;
    }

  }
  
  @media (min-width: 416px) and (max-width: 599px) {

    .roomCardImageContainerModal {
        max-width: 70dvw;
        min-width: 70dvw;
    }
  }
  
  @media (min-width: 600px) and (max-width: 767px) {

    .roomCardImageContainerModal {
        max-width: 90dvw;
        min-width: 90dvw;
    }

  }
  
  @media (min-width: 768px) and (max-width: 1199px) {
    .roomCardImageContainerModal {
        max-width: 100dvw;
        min-width: 100dvw;
    }

  }
  
  @media (min-width: 1200px) and (max-width: 1974px) {
    .roomCardImageContainerModal {
        max-width: 100dvw;
        min-width: 100dvw;
    }
  }
  
  @media (min-width: 1975px) and (max-width: 2559px) {

  }
  
  @media (min-width: 2560px) {
    

  }