.container-description {
  margin-bottom: 10px;
}

.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }
  
  /* Snow Theme */
 .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  
.ql-editor {
    min-height: 10rem;
    min-width: 40rem;
    max-height: 20rem;
    overflow: auto;
  }

  @media (max-width: 415px) {
    .buttonDelete {
        right: 3svw;
    }

  }
  
  @media (min-width: 416px) and (max-width: 599px) {

    .ql-editor {
      min-width: 0rem;
      max-width: 100%;
    }
  }
  
  @media (min-width: 600px) and (max-width: 767px) {
    .ql-editor {
      min-width: 0rem;
      max-width: 100%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1199px) {
    .container-description {
      max-width: 100%;
    }

    .ql-editor {
      min-width: 0rem;
      max-width: 100%;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1974px) {


  }
  
  @media (min-width: 1975px) and (max-width: 2559px) {


  }
  