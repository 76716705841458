@keyframes reduceWidth {
    from { width: 100%; }
    to { width: 0; }
  }
  

  .roomCardCarousel {
    display: flex;
    flex-direction: column;
    margin: 10px;
    border-radius: 5px;
    background-color: #ffffff; /* Adjust according to your needs */
    max-width: 500px; /* Adjust according to your needs */
    max-height: 800px;
  }
  
  .roomCardNoCarousel {
    display: flex;
    flex-direction: column;
    margin: 10px;
    border-radius: 5px;
    background-color: #ffffff; /* Adjust according to your needs */
    min-width: 500px;
  }
  
.roomCardContent {
    display: flex;
    justify-content: space-between;
    padding: 10px; /* Ajuste selon tes besoins */
}

.roomCard.booked {
    background-color: #ccc; /* Grisé */
    pointer-events: none; /* Rend l'élément non cliquable */
    color: #666; /* Texte foncé pour plus de contraste */
    border: 1px solid #2d004b;
}

.roomCardExtraBed {
    font-weight: 500;
}

.selected {
    border: 1px solid #2d004b; /* Bordure pour la carte sélectionnée */
}

.inCart {
    opacity: 0.5; 
}
  
.consulting {
    background-color: #7fc7ff; 
}

.groomRoom {
    border: 2px solid #ff0055; 
    min-width: 300px;
}

.groomRoomSelected {
    border: 2px solid #ad0c4a; 
}

.groomRoomp {
    color: #ff0055;
    margin-left: 5px;
}

.roomCustBorder {
    display: flex;
    flex-direction: column;
    margin: 10px;
    border-radius: 5px;
    background-color: #ffffff; /* Adapte selon tes besoins */
    max-width: 500px; /* Adapte selon tes besoins */
    max-height: 800px;
    border: 1px solid #aaaaaa;
}

.roomCardCustContent {
    display: flex;
    justify-content: space-between;
    padding: 10px; /* Ajuste selon tes besoins */
    min-width: 300px;
}

.roomCardH3 {
    font-weight: bold;    
    display: flex;
    align-items: center;
}

.roomCardImageContainer {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
    border-radius: 5px;
  }

  .roomCardImageContainerCarousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
  }
  
  .imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: center;
  }
  
  .roomCardCarouselImg {
    width: 100%; 
    height: 100%;
    object-fit: cover;
  }
  
  .roomCardNoCarousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

.expandButton {
    color: grey; /* Grise le texte */
    font-size: 16px; /* Ajuste la taille du texte */
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center; /* Centre le texte et l'icône verticalement */
    padding-left: 10px;
}
  
.chevronIcon {
    margin-left: 8px; /* Espace entre le texte et l'icône */
}

.buttonConfirmer {
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
    margin-bottom: 10px;
    width: 80%;
}

.buttonConfirmer:hover {
    background-color: #5465ff;
    /* hover:bg-blue-700 */
}

.buttonConfirmer:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
    /* focus:shadow-outline */
}

.buttonContainer {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    display: flex;
}

.roomName {
    font-weight: 600;
  }


  @media (max-width: 415px) {

    .roomCardNoCarousel {
        min-width: 85dvw;
    }

  }

  @media (min-width: 416px) and (max-width: 599px) {

    .roomCardNoCarousel {
      min-width: 85dvw;
  }

  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* .roomCardNoCarousel {
        min-width: 400px;
    } */

  }

  @media (min-width: 768px) and (max-width: 1199px) {

    /* .roomCardNoCarousel {
        min-width: 450px;
    } */

  }

  @media (min-width: 1200px) and (max-width: 1974px) {

  }

  @media (min-width: 1975px) and (max-width: 2559px) {
  }