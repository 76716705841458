.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  min-height: 170px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  align-content: center;
}

.lecture-fichier-input {
  border: 1px solid grey;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
}

.modal-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #2d004b;
  color: white;
  cursor: pointer;
  margin-left: 2vw;
}

.modal-button:hover {
  background-color: #5465ff;
}

.modal-title {
  font-weight: bold;
  margin-bottom: 2vh;
}

