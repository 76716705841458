.reservationH1 {
    color: #fff;
    font-weight: bold;
    font-size: 48px;
    margin-left: 20px;
}

/* .reservationH3 {
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    margin-left: 20px;
} */

.reservationH2 {
    color: #182125;
    font-weight: bold;
    font-size: 36px;
    margin-top: 40px;
    text-align: center;
}

.felicitations {
    color: #182125;
    font-weight: bold;
    font-size: 36px;
    margin-top: 40px;
    text-align: center;
}


.reservationH3 {
    color: #fff;
    font-weight: bold;
    font-size: 26px;
    margin-left: 20px;
}

.iconsAndTextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alignement à gauche */
}

.iconWithText {
    display: flex;
    align-items: center; /* Centre verticalement l'icône et le texte */
    margin-top: 10px; /* Ajuste selon le besoin pour l'espace entre les lignes */
}

.iconWithText span {
    margin-left: 15px; /* Ajuste l'espace entre l'icône et le texte */
}

.numberGuests {
    margin-left: 15px;
}

.resultatsChambresH1 {
    color:#182125;
    /* font-weight: bold; */
    font-size: 40px;
    margin-left: 4vw;
    margin-top: 1vw;    
    text-align: left;
}

.resultatsChambreP {
    color:#182125;
    font-size: 17px;
    font-style: italic;
    text-align: left;
    margin-bottom: 1vw;
    margin-left: 4vw;
}

.roomsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: 30px;
    margin-right: 30px;     /* ou space-between selon le rendu désiré */
}

.roomCard {
    flex: 0 1 20%;    /* Adaptez la valeur 20% selon la largeur souhaitée pour chaque carte */
    margin: 30px;    /* Espacement entre les cartes */
}

.venueContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  
.venueInfo {
    flex: 1;
    background-color: #182125;
    color: white;
    padding: 60px;
}

.venuName {
    color: #fff;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 20px;
}
  
.venueMap {
    flex: 1;
    z-index: 5;
    /*  height: 500px; Ensure a fixed height for the map container */
}

.customLaunchIcon {
    color: #fff;
    margin-left: 20px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.customLaunchIcon:hover {
    color: #5465ff; 
    transform: scale(1.2); 
}

.congratsP {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}

.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;  /* This will make the container take the full viewport height */
    width: 100vw;   /* This will make the container take the full viewport width */
}

.customBulletList {
    margin-left: 20px; 
    list-style-type: disc; 
}

.customBulletItem {
    margin-bottom: 5px; 
    font-size: 1rem; 
}

  @media (max-width: 415px) {
    .reservationH1 {
        font-size: 1.2rem;
        margin-left: 5px;
    }

    .reservationH2 {
        font-size: 1.1rem;
        margin-top: 10px;
    }

    .reservationH3 {
        font-size: 1rem;
        margin-left: 5px;
    }

    .iconWithText {
        margin-top: 5px; 
    }

    .venueContainer {
        flex-direction: column;
    }

    .venueInfo {
        padding: 30px; /* Adjust padding for smaller screens */
    }

    .venuName {
        font-size: 24px; /* Adjust font size for smaller screens */
    }

    .venueMap {
        /*margin-top: 20px;  Add margin for spacing between info and map */
        height: 300px;
        flex: none;
    }

    .resultatsChambresH1 {
        font-size: 28px;
    }

    .roomCard {
        max-width: 100%;
    }

    .felicitations {
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 0.985rem;
        padding: 0 20px;
    }

  }
  
  @media (min-width: 416px) and (max-width: 599px) {

    .reservationH1 {
        font-size: 1.2rem;
        margin-left: 5px;
    }

    .reservationH2 {
        font-size: 1.1rem;
        margin-top: 10px;
    }

    .reservationH3 {
        font-size: 1rem;
        margin-left: 5px;
    }

    .iconWithText {
        margin-top: 5px; 
    }

    .venueContainer {
        flex-direction: column;
    }

    .venueInfo {
        padding: 30px; /* Adjust padding for smaller screens */
    }

    .venuName {
        font-size: 24px; /* Adjust font size for smaller screens */
    }

    .venueMap {
        /*margin-top: 20px;  Add margin for spacing between info and map */
        height: 300px;
        flex: none;
    }

    .resultatsChambresH1 {
        font-size: 28px;
    }

    .roomCard {
        max-width: 100%;
    }

    .felicitations {
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 0.985rem;
        padding: 0 20px;
    }
  }
  
  @media (min-width: 600px) and (max-width: 767px) {

    .reservationH1 {
        font-size: 1.8rem;
        margin-left: 5px;
    }

    .reservationH2 {
        font-size: 1.5rem;
        margin-top: 10px;
    }

    .reservationH3 {
        font-size: 1.1rem;
        margin-left: 5px;
    }

    .iconWithText {
        margin-top: 5px; 
    }


    .venueContainer {
        flex-direction: column;
    }

    .venueInfo {
        padding: 40px; /* Adjust padding for small screens */
    }

    .venuName {
        font-size: 28px; /* Adjust font size for small screens */
    }

    .venueMap {
       /* margin-top: 20px;  Add margin for spacing between info and map */
        height: 400px;
        flex: none;
    }

    .resultatsChambresH1 {
        font-size: 28px;
    }

    .roomCard {
        max-width: 100%;
    }

    .felicitations {
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 0.985rem;
        padding: 0 20px;
    }

  }
  
  @media (min-width: 768px) and (max-width: 1199px) {

    .venueContainer {
        flex-direction: column;
    }

    .venueInfo {
        padding: 50px; /* Adjust padding for medium screens */
    }

    .venuName {
        font-size: 32px; /* Adjust font size for medium screens */
    }

    .venueMap {
        /*margin-top: 20px;  Add margin for spacing between info and map */
        height: 450px;
        flex: none;
    }

    .resultatsChambresH1 {
        font-size: 28px;
    }

    .roomCard {
        max-width: 100%;
    }

    .felicitations {
        font-size: 2rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 1.2rem;
        padding: 0 20px;
    }


  }
  
  @media (min-width: 1200px) and (max-width: 1974px) {

    .venueContainer {
        flex-direction: row;
    }

    .venueInfo {
        padding: 60px; /* Default padding */
    }

    .venuName {
        font-size: 36px; /* Default font size */
    }

    .venueMap {
        margin-top: 0; /* No additional margin */
    }

    .resultatsChambresH1 {
        font-size: 28px;
    }

    .roomCard {
        max-width: 100%;
    }

    .felicitations {
        font-size: 2rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 1.2rem;
        padding: 0 20px;
    }

  }
  
  @media (min-width: 1975px) and (max-width: 2559px) {

    .venueContainer {
        flex-direction: row;
    }

    .venueInfo {
        padding: 60px; /* Default padding */
    }

    .venuName {
        font-size: 36px; /* Default font size */
    }

    .venueMap {
        margin-top: 0; /* No additional margin */
    }

    .resultatsChambresH1 {
        font-size: 28px;
    }

    .roomCard {
        max-width: 100%;
    }

  }
  