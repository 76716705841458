.leftArrow, .rightArrow {
    position: absolute;
    top: 50%; 
    z-index: 9;
    cursor: pointer;
    border-radius: 50%; /* Ensures the div is round */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5);
}

.leftArrow {
    left: 10px;
    transform: translateY(-50%);
}
  
.rightArrow {
    right: 10px;
    transform: translateY(-50%);
}

.leftArrow:hover, .rightArrow:hover {
    transform: scale(1.2) translateY(-50%);
}

.leftArrow:hover::before, .rightArrow:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Adjust to inherit the size */
    height: 100%; /* Adjust to inherit the size */
    border-radius: 50%; /* Keep it round */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.5); /* Same shadow as parent */
    z-index: -1;
}
