@media screen and (max-width: 1921px) and (min-width: 1001px) {

  .card-container {
    width: 100%;
    max-width: 480px; /* Ajustez la largeur maximale selon vos besoins */
    }

  .form-container {
    width: 80%;
    max-width: 480px !important;
  }

  .password-strength-bar {
    width: 78% !important;
    height: 5px;
    background-color: #ddd;
    margin-top: 5px;
  }

}

.custom-card {
    width: 400px; /* Définir la largeur souhaitée */
  }

.card-container {
width: 100%;
max-width: 600px; /* Ajustez la largeur maximale selon vos besoins */
margin: 0 auto; /* Centre les cartes horizontalement */
margin-bottom: 20px; /* Ajoute un espace entre les cartes */
}

.card-container .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.custom-form-item .ant-form-item-label::before {
content: "*";
color: red;
margin-right: 4px;
}

.container-form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ajustez la hauteur selon vos besoins */
  }

.form-container {
  width: 80%;
  max-width: 600px;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
}

.cust-collaps-login .ant-collapse-header {
  background-color: #ffffff; /* couleur de fond */
}

.custom-form-login, .ant-collapse-content-box {
  background-color: #ffffff; /* Mettez ici la couleur de fond que vous voulez */
  margin: 0; /* Supprimer les marges */
  padding: 0; /* Supprimer les paddings */
}

.register-button {
    background-color: #2d004b; /* Couleur de fond lorsque la souris passe dessus */
 }

 .register-button:hover {
  background-color: #5465ff !important; /* Couleur de fond lorsque la souris passe dessus */
}

.google-login {
  margin-top: 10px;
}

.password-indicator {
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  width: 100%; 
}

.password-strength-bar {
  width: 80%;
  height: 5px;
  background-color: #ddd;
  margin-top: 5px;
}

.password-strength-bar-fill {
  height: 5px;
  transition: width 0.3s ease;
}

.password-text-indicator {
  font-weight: 900;
  margin-left: 5px;
}

.pricing-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 60px;
  border-bottom: 1px solid #818a977d;
  padding: 15px 0;
}

.toggle-label {
  font-size: 16px;
  margin: 0 10px;
  color: rgb(100, 116, 139);
}

.toggle-checkbox {
  display: none;
}

.toggle-switch {
  width: 50px;
  height: 25px;
  background: #2d004b; /* Grey background for default */
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease; /* Transition for the background */
}

.toggle-checkbox:checked + .toggle-switch {
  background: rgb(99, 102, 241); /* Purple background when checked */
}

.toggle-switch::after {
  content: '';
  width: 23px;
  height: 23px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease;
}

.toggle-checkbox:checked + .toggle-switch::after {
  transform: translateX(25px);
}

.reset-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.reset-label {
  margin-bottom: 20px;
}

.reset-input {
  border: 1px solid #ccc;
  margin-left: 10px;
  border-radius: 4px;
  width: 200px;
}

.reset-title {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.reset-button-container {
  display: flex;
  justify-content: center;
}

/* Button */
.reset-button-container {
  display: flex;
  justify-content: center;
}

.reset-button {
  background-color: #5465ff;
  color: white;
  border: none;
  padding: 2px 50px;
  font-size: 0.875rem;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  display: inline-flex; /* To align text and icon properly */
  align-items: center;
  justify-content: center;
}

.reset-button:hover {
  background-color: #4e4ce7;
}

.strength-text-0 { color: #ff3e36; }
.strength-text-1 { color: #ff691f; }
.strength-text-2 { color: #ffda36; }
.strength-text-3 { color: #0fdd87; }
.strength-text-4 { color: #22d659; }

.strength-0 { width: 5%; background-color: #ff3e36; }
.strength-1 { width: 25%; background-color: #ff691f; }
.strength-2 { width: 50%; background-color: #ffda36; }
.strength-3 { width: 75%; background-color: #0fdd87; }
.strength-4 { width: 100%; background-color: #22d659; }
