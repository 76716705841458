.container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    margin-top: 10px;
    width: 100%;
}

.container span {
    margin: 10px 0;
}