.dashboard {
    background-color: #fff; /* Fond blanc ou une autre couleur claire */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre subtile pour un effet de profondeur */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 100px; /* Centrage et espace autour */
  }
  
  .button {
    background-color: #5465ff; /* Couleur de fond du bouton */
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transition pour l'interaction du bouton */
  }
  
  .button:hover {
    background-color: #2d004b; /* Changement de couleur au survol */
  }
  
  h2 {
    color: #2d004b; /* Couleur du texte */
    margin-bottom: 15px; /* Espacement avant le bouton */
  }
  