.slick-slide .image {
    border-radius: 5px;
    min-width: 150px;
    max-width: 100%; /* Assure que l'image ne dépasse pas la largeur du container */
    max-height: 300px; /* Définis une hauteur maximale pour les images */
    object-fit: cover; /* Couvre le container tout en conservant les proportions */
    width: 100%; /* Assure que l'image couvre la largeur */
    height: auto; /* Assure que l'image conserve sa hauteur proportionnelle */
}

.slick-dots {
    position: absolute;
    bottom: 5px; /* Ajuste cette valeur pour déplacer les dots plus haut ou plus bas */
    left: 0;
    width: 100%;
    text-align: center;
}

.slick-dots li button:before {
    color: white; /* Couleur des dots */
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #ffffff; /* Couleur du dot actif */
}