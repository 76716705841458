.closeButton {
    position: absolute;
    top: 23px;
    right: 28px;
    background: none;
    border: none;
    cursor: pointer;
    color: red; /* La couleur de l'icône */
    font-size: 24px; /* La taille de l'icône */
    z-index: 9999;
}

.buttonContainer {
    display: flex; /* Activez Flexbox */
    justify-content: center; /* Centre horizontalement */
    margin-top: 10px; /* Espacement en haut */
    padding: 10px; /* Ajoutez du padding si nécessaire */
}

.buttonTerminer {
    background-color: #2d004b; /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
}

.buttonTerminer:hover {
    background-color: #5465ff;    /* hover:bg-blue-700 */
}