@media screen and (max-width: 1921px) and (min-width: 1001px) {
  .barChartContainer {
    flex: 1;
    /* margin: 10px; */
    height: 300px !important; /* ou la hauteur désirée */
    width: 100%; /* ou la largeur désirée */
  }
}

.barChartContainer {
    flex: 1;
    /* margin: 10px; */
    height: 380px; /* ou la hauteur désirée */
    width: 100%; /* ou la largeur désirée */
  }

  .title {
    text-align: center;
  }