.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 32%; /* Augmentez la largeur */
    text-align: left; /* Alignez le texte à gauche */
    font-family: 'Roboto', sans-serif;
    overflow: auto; /* Gérer l'overflow si nécessaire */
    max-height: 100%; /* Limiter la hauteur maximale */
}

.modalContent h2 {
    font-size: 1.5rem; /* ou la taille que vous souhaitez */
    font-weight: bold; /* pour rendre le titre plus marquant */
    margin-bottom: 45px; /* espace entre le titre et le paragraphe */
}

.modalContent p {
    margin-left: 30px;
}


.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px; /* Ajustez en fonction de vos besoins */
}

.buttonConfirmer {
    background-color: #2d004b; /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
}

.buttonConfirmer:hover {
    background-color: #5465ff;    /* hover:bg-blue-700 */
}

.highlight {
    font-weight: bold;
    color: #3324bb; /* ou toute autre couleur bleue de votre choix */
}

.buttonRetour {
    background-color: #eb9900; /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
}

.buttonRetour:hover {
    background-color: #2d004b;    /* hover:bg-blue-700 */
}

.flipIcon {
    transition: transform 0.5s ease; /* Durée et style de transition */
    display: inline-block; /* Nécessaire pour appliquer la transformation */
    margin-right: 10px;
}

.flipIcon:hover {
    transform: rotateY(180deg); /* Retourner l'icône */
}
