/* PartnerRegistration.module.css */

/* Section 1: Intro */
.partnerRegistrationPage {
    font-family: Arial, sans-serif;
}

.introSection {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 50px;
    background: radial-gradient( #a668ff, #1d2856);
    color: white;
    margin-top: 100px;
    min-height: 100svh;
}

.introText {
    max-width: 600px;
}

.introText span {
    font-size: 1.5rem;
}

.introLogo {
    max-width: 70%;
    margin-bottom: 100px;
}

.introImage img {
    border-radius: 10px;
    width: 500px;
    height: auto;
}

.ctaButton {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #5465ff;
    color: white;
    border: 1px solid;
    cursor: pointer;
    border-radius: 4px;
}

.ctaButton:hover {
    background-color: #2d004b;
}

/* Section 2: Plan Selection */
.plansSection {
    padding: 50px;
    min-height: 100svh;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.plansSection h1 {
    font-size: 2.5rem;
}

/* .planCard {
    background-color: #f4f4f4;
    padding: 20px;
    margin: 10px;
    width: 260px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;
    padding-top: 30px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
}

.modalBody form {
    display: flex;
    flex-direction: column;
    /* Vertical arrangement of form-row elements */
    gap: 15px;
    /* Space between form rows */
}

.formContainer {
    margin-top: 30px;
}

/* Each row containing label and input */
.formRow {
    display: flex;
    align-items: center;
    /* Align items vertically in the center */
    justify-content: space-between;
    /* Space between label and input */
}

/* Label styling */
.formRow label {
    flex: 1;
    /* Make the label take up space equally */
    font-size: 1rem;
    padding-right: 10px;
    /* Add some spacing between label and input */
    text-align: right;
    /* Align label text to the right */
}

/* Input styling */
.formRow input {
    flex: 2;
    /* Make the input field larger than the label */
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.labelContainer {
    width: 150px; /* Fixed width for labels */
    flex-shrink: 0; /* Prevent label from shrinking */
    display: flex;
    align-items: center;
  }

.inputContainer {
flex-grow: 1; /* Input takes up the remaining space */
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
}

.modalTitle {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 1.8rem;
}

.modalBody {
    margin: 20px 0;
}


.button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #2d004b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #5465ff;
}

.closeButton {
    position: absolute;
    top: 35px;
    right: 8px;
    background: none;
    border: none;
    cursor: pointer;
    color: red;
    font-size: 24px;
    z-index: 9999;
}

.confirmButtonContainer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    /* Espace avant le bouton */
}

.checkboxInput {
    margin-right: 4px;
}

.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* This will make the container take the full viewport height */
    width: 100vw;
    /* This will make the container take the full viewport width */
}

.tooltipText {
    max-width: 450px;/* Restrict the tooltip width */
    width: 100%;/* Make the tooltip take up the container's width */
    background-color: #f8f8f8;/* Slightly different background */
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);/* Optional: Add some shadow */
    font-size: 0.85rem;
    margin: 5px 0;
    z-index: 10000;/* Higher than modal */
    word-wrap: break-word;/* Break long words */
    white-space: normal;/* Allow text to wrap normally */
    overflow: hidden;/* Prevent overflow */
}

.tooltipContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.infoButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 5px; /* Space between label and button */
}

.rationale {
    margin-top: 10px;
    color: #009933; /* Green color to highlight savings */
    font-size: 14px;
    font-style: italic;
    text-align: center; /* Center-align to make it more prominent */
  }
  
  .planCard .price {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #1d2856;
  }
  
  .planCard .rationale {
    margin-top: 10px;
  }

  .venueCreationSection {
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .sectionTitle {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #202020;
    margin-bottom: 30px;
  }
  
  .stepContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
  
  .step {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 20px;
    text-align: center;
  }
  
  .textContainer {
    max-width: 600px;
  }
  
  .textContainer h3 {
    font-size: 20px;
    font-weight: bold;
    color: #3a3a3a;
  }
  
  .textContainer p {
    font-size: 16px;
    color: #414141;
    line-height: 1.6;
  }

  .thirdStep {
    max-width: 900px;
  }

  .thirdStep h3 {
    font-size: 20px;
    font-weight: bold;
    color: #3a3a3a;
  }
  
  .thirdStep p {
    font-size: 16px;
    color: #414141;
    line-height: 1.6;
  }
  
  .imageContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .formScreenshot {
    max-width: 65%;
    height: auto;
    border: 2px solid #ddd;
    border-radius: 8px;
  }

  .ctaSection {
    margin-top: 40px;
    text-align: center;
  }
  
  .ctaSection p {
    font-size: 18px;
    color: #3a3a3a;
    margin-bottom: 20px;
  }
  

@media (max-width: 415px) {
    .modalTitle {
        font-size: 1.5rem;
    }

    .introSection {
        margin-top: 50px;
        padding: 25px;
        gap: 10px;
    }

    .introText {
        max-width: 430px;
    }

    .introText span {
        font-size: 0.875rem;
    }

    .introLogo {
        margin-bottom: 70px;
    }

    .introImage {
        width: 1000px;
    }

    .plansSection {
        margin-top: 300px;
        padding-top: 100px;
    }

    .step {
        flex-direction: column;
    }

    .formScreenshot {
        max-width: 100%;
    }

    .venueCreationSection {
        padding: 0px
    }

}

@media (min-width: 416px) and (max-width: 599px) {
    .modalTitle {
        font-size: 1.5rem;
    }

    .introSection {
        margin-top: 50px;
        padding: 25px;
        gap: 10px;
    }

    .introText {
        max-width: 430px;
    }

    .introText span {
        font-size: 0.875rem;
    }

    .introLogo {
        margin-bottom: 70px;
    }

    .introImage {
        width: 1000px;
    }

    .plansSection {
        margin-top: 300px;
        padding-top: 100px;
    }

    .step {
        flex-direction: column;
    }

    .formScreenshot {
        max-width: 100%;
    }

    .venueCreationSection {
        padding: 0px
    }

}

@media (min-width: 600px) and (max-width: 767px) {
    .modalTitle {
        font-size: 1.5rem;
    }

    .introSection {
        margin-top: 70px;
        padding: 25px;
        gap: 10px;
    }

    .introText {
        max-width: 350px;
    }

    .introText span {
        font-size: 1.2rem;
    }

    .introLogo {
        margin-bottom: 55px;
    }

    .plansSection {
        padding-top: 100px;
    }

    .step {
        flex-direction: column;
    }

    .formScreenshot {
        max-width: 100%;
    }

    .venueCreationSection {
        padding: 0px
    }

}

@media (min-width: 768px) and (max-width: 1199px) {

    .modalTitle {
        font-size: 1.5rem;
    }

    .form-row {
        flex-direction: column;
        align-items: flex-start;
        /* Align items to the left */
    }

    .form-row label {
        text-align: left;
        /* Align label text to the left for small screens */
        margin-bottom: 5px;
        /* Add some space below the label */
    }

    .form-row input {
        width: 100%;
        /* Make input take up full width */
    }

    .modalTitle {
        font-size: 1.5rem;
    }

    .introSection {
        margin-top: 70px;
        padding: 25px;
        gap: 10px;
    }

    .introText {
        width: 415px;
    }

    .introText span {
        font-size: 1.4rem;
    }

    .introLogo {
        margin-bottom: 55px;
    }

    .modalBody p {
        font-size: 1.2rem;
    }

    .formScreenshot {
        max-width: 100%;
    }

    .venueCreationSection {
        padding: 0px
    }

    .imageContainer {
        min-width: 350px;
    }
}

@media (min-width: 1200px) and (max-width: 1974px) {

    .modalBody p {
        font-size: 1.2rem;
    }
    
    .introSection {
        margin-top: 70px;
        padding: 25px;
        gap: 10px;
    }
}

@media (min-width: 1975px) and (max-width: 2559px) {}

@media (min-width: 2560px) {}