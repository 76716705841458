.inputDonationContainer {
    margin-bottom: 0.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.labelInputDonation {
    margin-right: 1vw;
}

.inputDonation {
    border: 1px solid #182125;
    border-radius: 3px;
}

.devise {
    margin-left: 0.5vw;
}
  
  .label {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .inputContainer {
    display: flex;
    align-items: center;
  }
  
  .input {
    width: 80px;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    text-align: center;
    margin-right: 8px;
  }
  
  .chevronContainer {
    display: flex;
    flex-direction: column;
  }
  
  .chevronButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;
  }
  
  .quickAddContainer {
    margin-top: 12px;
    display: flex;
    gap: 8px;
  }
  
  .quickAddButton {
    background-color: #5465ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .quickAddButton:hover {
    background-color: #2d004b;
  }
  