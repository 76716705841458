@media screen and (max-width: 1921px) and (min-width: 1001px) {
  .waffleChartContainer {
    margin: 10px;
    height: 300px !important; 
    width: 100%;
  }
}

.waffleChartContainer {
    flex: 1;
    margin: 10px;
    height: 380px; /* ou la hauteur désirée */
    width: 100%; /* ou la largeur désirée */
  }

  .title {
    text-align: center;
  }