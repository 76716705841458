/* DateSelector.module.css */
.dateSelectorContainer {
    display: flex;
    justify-content: center; /* Centre les DatePicker horizontalement */
    align-items: center;
    margin: 50px 0;
    width: 100%; /* Assurez-vous qu'il prend la largeur complète */
  }

.dateSelectorContainer:last-child {
    margin-right: 0;
    /* Pas de marge à droite pour le dernier élément */
}

.dateSelectorLabel {
    margin-bottom: 5px;
    font-weight: bold;
}

.dateSelectorInput {
    padding: 10px 20px;
    border-radius: 10px;
    /*border: 1px solid #ccc; */
    /* outline: none; */
    width: 80%;
    /* padding: 10px; */
    border: 1px solid #ccc;
    outline: none;
    font-size: 1rem;
    /* Ajustez la taille de police si nécessaire */
}

.dateSelectorInput:focus {
    border-color: #5465ff;
}

.dateSelectorContainer label {
    margin-bottom: 10px;
}

