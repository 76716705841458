.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.title {
    margin-top: 20vh;
    font-weight: 600;
    font-size: 2.2rem;
}

.description {
    font-weight: 200;
    font-style: italic;
}

.title, .description {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stripeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 3vh; /* Assurez-vous que le conteneur prend toute la largeur disponible */
}

.payoutContainer {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center; 
    margin-right: 50vh;
}

.stripeContainer {
    display: flex;
    flex-direction: row;
}

.buttonPayout {
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-left: 65px;
}

.buttonPayout:hover {
    background-color: #5465ff;
    /* hover:bg-blue-700 */
}

.buttonPayout:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
    /* focus:shadow-outline */
}

.message {
    font-style: italic;
    font-size: 1.1rem;
    font-size: 500;
}

.topTableContainer {
    display: flex;
    align-items: center;
    padding-top: 5vh; /* Adjust as needed */
    justify-content: center;
}

.reminderContainer {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between icon, label, and select */
    /*margin-right: 10vh;  Pushes this container to the left */
}

.reminderSelect {
    padding: 8px; /* Adjust padding as needed */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.withdrawalContainer {
    display: flex;
    align-items: center;
    /*margin-left: 10vh;  Pushes this container to the right */
}
