.deposerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 100vh;

}

.deposerContainerH1 {
    font-weight: 600;
    font-size: 2rem;
    padding-bottom: 0.9vh;
}

.deposerContainerH2 {
    font-weight: 500;
    font-size: 1.2rem;
}

.deposerContainerP {
    font-weight: 400;
    font-size: 0.9rem;
    font-style: italic;
}


.sampleSection {
    margin-top: 20px;
}

.imagesContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 8vw;
}

.exampleImage {
    width: 25vw;
    height: auto;
}

.downloadButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #a0a0a0;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0.5vh;
}

.downloadButton:hover {
    background-color: #5a656a;
    color: #ffffff;
}

.downloadButton:hover .downloadIcon {
    color: #ffffff; /* Change icon color on hover */
}


.createListButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0.5vh;
}

.createListButton:hover {
    background-color: #218838;
}

.buttonUpload {
    background-color: #2d004b;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin-top: 1vh;
}

.buttonUpload:hover {
    background-color: #5465ff;
    color: #ffffff;
}

.buttonUpload:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
}

.buttonUpload.inactive {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

.noFile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
}