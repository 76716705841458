.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 8999;
}

.modalContent {
    background-color: white; /* Ensures the modal is opaque */
    padding: 20px;
    border-radius: 8px;
    max-width: 600px; /* Controls the width of the modal */
    width: 90%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
}

.modalTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modalLogo {
    width: 100px;
}

.modalTitle {
    font-weight: bold;
    margin-left: 10px;
    flex-grow: 1;
    font-size: 1.5rem;
}

.modalDescription {
    margin-bottom: 20px;
}

.modalImagesContainer {
    display: flex;
    align-items: center;
}

.modalImages {
    flex: 1;
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 20px;
    scroll-behavior: smooth;
}

.modalImage {
    flex: none;
    max-height: 200px;
    object-fit: contain;
}

.modalActions {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

.modalButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #2d004b;
    color: white;
    cursor: pointer;
}

.modalButton:hover {
    background-color: #5465ff;
}

.scrollArrow {
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0 10px;
}