.rateInputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centre les enfants horizontalement */
    margin-top: 50px;
    gap: 10px;
    /* margin-left: 40px; */
}

.inputWithEuro {
    position: relative;
    display: inline-block;
    border-radius: 10px;
    border: 1px solid #CCCCCC; /* Ajoutez une bordure légère */
    background-color: white; /* Assurez-vous que l'arrière-plan soit blanc (ou toute autre couleur de fond désirée) */
    padding: 5px 10px; /* Ajoutez un peu de padding pour l'esthétique */
    /* Autres styles nécessaires */
}

.inputWithEuro::after {
    content: '€';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.inputWithEuroInput:focus {
    outline: none; /* Supprime la bordure de focus par défaut */
    /* Vous pouvez ajouter une bordure personnalisée si nécessaire */
    /*border: 1px solid #666666;  Exemple de bordure personnalisée */
}


.inputWithEuroInput {
    padding-right: 20px;
    width: 90px;
    border: none;
    /* Espace pour le symbole € */
    /* Autres styles de votre champ de saisie */
}

.paymentCalculatorDescription {
    margin-bottom: 20px;
    text-align: left; 
    font-size: 0.9rem;
    width: 100%; 
}

.calculatorTitle {
    text-align: center;
    font-weight: bold;
}

.priceInputRow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Espacement entre les éléments */
}

.priceSelectorDescription {
    font-size: 0.9rem;
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 70px;
    text-align: left;    /* Alignement à gauche */
    width: 100%;    /* Assurez-vous qu'il prend la largeur complète */
}

.buttonConfirmer {
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
}

.buttonConfirmer:hover {
    background-color: #5465ff;
    /* hover:bg-blue-700 */
}

.buttonConfirmer:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
    /* focus:shadow-outline */
}

.buttonContainer {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px
}