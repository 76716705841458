.customTabContainer {
    text-align: right;
    background-color: #182125; /* Ajustez en fonction du thème de votre éditeur */
    border-bottom: 1px solid #d3d3d3; /* Ajustez en fonction du thème de votre éditeur */
}
  
.customSaveTab {
    background-color: transparent; /* Intérieur transparent */
    border: none; /*1px solid #5465ff;  Bordure de couleur #5465ff */
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    margin-right: 40px;
    /* Autres styles pour correspondre à l'apparence de vos onglets */
}

.customSaveTab:hover {
    background-color: #5465ff; /* Coloration de l'intérieur lors du survol */
 /* Changement de la couleur du texte pour assurer une bonne visibilité */
}

.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;  /* This will make the container take the full viewport height */
    width: 100vw;   /* This will make the container take the full viewport width */
  }

.loadingMessage {
    margin-top: 10px; /* Ajustez selon vos besoins */
    color: #000000; /* Choisissez la couleur de texte appropriée */
    font-weight: bold; /* Pour mettre le texte en gras */
}