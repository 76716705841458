/* GoogleReviewsCarousel.module.css */

.carouselContainer {
  width: 100%;
  overflow: auto; /* Hide the overflowing content */
  padding: 20px;
  align-items: center;
  background-color: #545ca6;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Add some margin below the title */
}

.googleIcon {
  font-size: 2rem; /* Adjust the size of the Google icon */
  margin-bottom: 10px; /* Add some space below the icon */
}

.title {
  text-align: center;
  margin: 0; /* Remove default margin for h3 */
  font-weight: 800; /* Make the title more visible */
  color: #ffffff;
  font-size: 1.5rem; /* Base font size for title */
}

.carousel {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 10px 0; /* Add padding for better spacing */
  gap: 15px; /* Add some spacing between items */
}

.carouselItem {
  min-width: 250px; /* Set a minimum width for each item */
  background: #f5f5f5; /* Light background for each item */
  padding: 15px;
  border-radius: 8px; /* Rounded corners for each item */
  text-align: left; /* Left-align text */
  box-shadow: 0 10px 10px #0006; /* Subtle shadow for better visibility */
  max-width: 300px; /* Max width for readability */
  margin: 10px; /* Margin around each review */
  font-size: 0.9rem; /* Base font size for review text */
}

.starsContainer {
  display: flex; /* Display stars in a row */
  align-items: center; /* Center the stars vertically */
  margin: 5px 0; /* Add margin for spacing */
  color: #FFD700; /* Set color for stars to gold */
}

.starFilled,
.starHalf,
.starEmpty {
  margin-right: 3px; /* Adjust margin between stars */
  font-size: 1.2rem; /* Adjust size of stars */
}

.authorContainer {
  display: flex; /* Use flexbox for horizontal alignment */
  align-items: center; /* Center content vertically */
  margin-bottom: 10px; /* Add some margin below the author's name */
}

.authorImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px; /* Space between the image and the author's name */
}

.authorName {
  color: #000;
  text-decoration: none; /* Remove underline */
}


/* Extra Small Devices (max-width: 350px) */
@media (max-width: 350px) {
  .carouselItem {
    min-width: 200px;
    font-size: 0.8rem; /* Smaller text for smaller screens */
  }
  .title {
    font-size: 1.3rem; /* Adjusted title size */
  }
}

/* Small Devices, Phones (351px to 415px) */
@media (min-width: 351px) and (max-width: 415px) {
  .carouselItem {
    min-width: 220px;
    font-size: 0.85rem; /* Slightly larger text */
  }
  .title {
    font-size: 1.4rem; /* Adjusted title size */
  }
}

/* Phones (416px to 599px) */
@media (min-width: 416px) and (max-width: 599px) {
  .carouselItem {
    min-width: 250px;
    font-size: 0.9rem; /* Base text size */
  }
  .title {
    font-size: 1.5rem; /* Default title size */
  }
}

/* Small Devices, Phones (600px to 767px) */
@media (min-width: 600px) and (max-width: 767px) {
  .carouselItem {
    min-width: 280px;
    font-size: 1rem; /* Slightly larger text */
  }
  .title {
    font-size: 1.6rem; /* Larger title */
  }
}

/* Medium Devices, Tablets (768px to 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .carouselItem {
    min-width: 320px;
    font-size: 1.1rem; /* Increase text size */
  }
  .title {
    font-size: 1.7rem; /* Slightly larger title */
  }
}

/* Large Desktops (1200px to 1439px) */
@media (min-width: 1200px) and (max-width: 1439px) {
  .carouselItem {
    min-width: 350px;
    font-size: 1.1rem; /* Larger text */
  }
  .title {
    font-size: 1.8rem; /* Larger title */
  }
}

/* High-DPI screens or larger desktops (1440px and up) */
@media (min-width: 1440px) {
  .carouselItem {
    min-width: 400px; /* Increased card size for larger screens */
    max-width: 500px; /* Set maximum width */
    font-size: 1.1rem; /* Larger text size */
  }
  .title {
    font-size: 2rem; /* More prominent title size */
  }
  .googleIcon {
    font-size: 4rem; /* Adjust the size of the Google icon */
  }
}

/* Further adjustments for High-DPI screens with different resolutions */
@media (min-width: 1440px) and (min-resolution: 2dppx), 
       (min-width: 1440px) and (-webkit-min-device-pixel-ratio: 2), 
       (min-resolution: 192dpi) { /* Detect HiDPI screens with 192dpi or higher */
  .carouselItem {
    font-size: 0.875rem;
    max-width: 350px;
    min-width: 350px;
  }
  .title {
    font-size: 2.2rem; /* Larger title for better visibility */
  }
  .googleIcon {
    font-size: 4rem; /* Adjust the size of the Google icon */
  }
}

/* Adjust logo size for 1440x900 laptop screens specifically */
@media (min-width: 1200px) and (max-width: 1600px) and (min-height: 800px) and (max-height: 1000px) {
  .carouselItem {
    min-width: 350px; /* Adjust card size */
    max-width: 450px; /* Adjust maximum width */
    font-size: 1.1rem; /* Adjust text size */
  }
  .title {
    font-size: 1.9rem; /* Adjusted title size */
  }
  .googleIcon {
    font-size: 4rem; /* Adjust the size of the Google icon */
  }
}
