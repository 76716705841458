.modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 32%; /* Augmentez la largeur */
    text-align: left; /* Alignez le texte à gauche */
    font-family: 'Roboto', sans-serif;
    overflow: auto; /* Gérer l'overflow si nécessaire */
    max-height: 100%; /* Limiter la hauteur maximale */
}

.modalContent h2 {
    font-size: 1.5rem; /* ou la taille que vous souhaitez */
    font-weight: bold; /* pour rendre le titre plus marquant */
    margin-bottom: 45px; /* espace entre le titre et le paragraphe */
}

.modalButtons {
    display: flex;
    justify-content: space-around; /* Aligner les boutons avec un espace autour */
    padding-top: 20px; /* Espacement au-dessus de la zone des boutons */
}

.buttonClose, .buttonConfirm {
    cursor: pointer;
    padding: 10px 20px; /* Ajustez selon vos besoins */
    margin: 0 10px; /* Espacement entre les boutons */
    border: none;
    border-radius: 5px; /* Arrondir les bords */
    /* Ajoutez d'autres styles comme la couleur de fond, la couleur du texte, etc. */
}

.buttonConfirm {
    background-color: #2d004b; /* Couleur de fond pour le bouton Confirmer */
    color: white; /* Couleur du texte pour le bouton Confirmer */
}

.buttonClose {
    background-color: #eb9900; /* Couleur de fond pour le bouton Fermer */
    color: white; /* Couleur du texte pour le bouton Fermer */
}

.buttonConfirm:hover {
    background-color: #5465ff
}

.buttonClose:hover {
    background-color: #2d004b
}

.flipIcon {
    transition: transform 0.5s ease; /* Durée et style de transition */
    display: inline-block; /* Nécessaire pour appliquer la transformation */
    margin-right: 10px;
}

.flipIcon:hover {
    transform: rotateY(180deg); /* Retourner l'icône */
}