@media screen and (max-width: 1921px) and (min-width: 1001px) {
    .selectedItem {
        text-align: center;
        font-size: 0.785rem;
        /* Réduire la taille du texte */
        width: 80%;
        margin-left: 20px;
        /* margin-top: 10px; */
    }   

    .controlItem h2 {
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 10px;
        font-size: 0.9rem
    }

    .container {
        display: grid;
        grid-template-columns: repeat(2, 2fr);
        column-gap: 75px;
        justify-items: center;
        width: 100%;
        overflow: auto;
    }
    
    
  }

.container {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    column-gap: 90px;
    /* grid-gap: 15px; */
    justify-items: center;
    /* align-items: center; */
    /* margin-bottom: 20px; */
    width: 100%;
}

.controlItem {
    /* max-width: 500px; */
    /* Limite la largeur maximale */
    text-align: center;
    display: flex;
    flex-direction: column;
    /* Les enfants sont empilés verticalement */
    align-items: center;
    width: 100%;
}

.controlItem h2 {
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 10px;
}

.controlItem:last-child {
    grid-column: span 1;
    /* Le dernier élément s'étend sur deux colonnes */
}

.rateInput {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.selectedItem {
    text-align: center;
    font-size: 14px;
    /* Réduire la taille du texte */
    width: 80%;
    margin-left: 20px;
    margin-top: 10px;
}

.IconInfo:hover::after {
    content: "Le tarif spécial enfants est désactivé car un tarif spécial famille est appliqué.";
    position: absolute;
    background-color: #191e4c;
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    top: -5px;
    left: 105%;
    white-space: nowrap;
    z-index: 1;
}

.buttonConfirmer {
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
}

.buttonConfirmer:hover {
    background-color: #5465ff;
    /* hover:bg-blue-700 */
}

.buttonConfirmer:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
    /* focus:shadow-outline */
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px
}