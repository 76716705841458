.balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9; /* Fond légèrement gris pour se démarquer */
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Ombre pour donner de la profondeur */
    align-self: center;

  }
  
  .balance h3 {
    font-size: 1.2rem;
    color: #182125; 
    margin-bottom: 10px; 
  }
  
  .balance p {
    font-size: 1.4rem;
    color: #2d004b; /* Utilisez une couleur thématique pour le montant */
    font-weight: bold;
    margin: 0;
    text-align: end;
  }
  
  .icon {
    color: #635BFF; /* Couleur de l'icône */
    margin-right: 10px;
  }
  