.topBanner {
    display: flex;
    align-items: center;
    background-color: #182125;
    position: relative; /* pour le positionnement absolu du divider et du h1 */
    transition: all 0.5s ease; /* transition pour le déplacement du logo et l'apparition du h1 */
    padding: 30px 0;
  }
  
.logo {
    /* Ajustez selon la taille réelle de votre logo */
    width: 150px; 
    height: auto;
    display: inline-block;
    margin-left: 50%; 
    transition: margin-left 0.5s ease;
}

.headerSpacer {
    height: 6rem; /* Ajustez à la hauteur de votre header */
  }

.h1AndDivider {
    position: absolute;
    left: 50%; /* Commencer au milieu */
    transform: translateX(-50%); /* Centrer le h1 et le divider */
    display: flex;
    align-items: center;
    opacity: 0; /* Le h1 et le divider sont initialement cachés */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Transition pour les faire apparaître et bouger */
}
  
.divider {
    width: 1px;
    height: 0; /* Commencez par une hauteur de 0 pour l'animer */
    background-color: #ffffff;
    margin: 0 10px;
    transition: height 0.5s ease; /* Animez la hauteur */
}
  
.dividerAnimate {
    height: 210px; /* Hauteur finale pour le divider animé */
}

.h1AndDividerAnimate {
    transform: translateX(0); /* Placez le h1 et le divider à leur emplacement final */
    opacity: 1; /* Rendez le h1 et le divider complètement visibles */
}

.logoAnimate {
    margin-left: 15rem; /* Placez le logo à son emplacement final sur la gauche */
}

.reservationH1 {
    opacity: 0; /* Commencez avec le texte complètement transparent */
    transition: opacity 0.5s ease-in-out; /* Transition pour l'opacité */
}
  
.h1Animate {
    opacity: 1; /* Rendez le texte complètement opaque */
}

@media (max-width: 415px) {
    .logo {
        width: 90px; /* Smaller logo for small screens */
    }

    .logoAnimate {
        margin-left: 1rem; /* Adjust final logo position for small screens */
    }

    .dividerAnimate {
        height: 100px; /* Adjust divider height for small screens */
    }

}

/* Small Devices, Phones (416px to 599px) */
@media (min-width: 416px) and (max-width: 599px) {
    .logo {
        width: 110px;
    }

    .logoAnimate {
        margin-left: 1.3rem;
    }

    .dividerAnimate {
        height: 150px;
    }

}

/* Medium Devices, Tablets (600px to 767px) */
@media (min-width: 600px) and (max-width: 767px) {
    .logo {
        width: 120px;
    }

    .logoAnimate {
        margin-left: 3rem;
    }

    .dividerAnimate {
        height: 180px;
    }

}

/* Large Devices, Tablets (768px to 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
    .logo {
        width: 130px;
    }

    .logoAnimate {
        margin-left: 5rem;
    }

    .dividerAnimate {
        height: 200px;
    }

}

/* Extra Large Devices, Desktops (1200px to 1974px) */
@media (min-width: 1200px) and (max-width: 1974px) {
    .logo {
        width: 150px;
    }

    .logoAnimate {
        margin-left: 9rem;
    }

    .dividerAnimate {
        height: 210px;
    }

    .headerSpacer {
        height: 4.9rem; /* Ajustez à la hauteur de votre header */
      }

}

/* Extra Extra Large Devices, Desktops (1975px to 2559px) */
@media (min-width: 1975px) and (max-width: 2559px) {
    .logo {
        width: 180px;
    }

    .logoAnimate {
        margin-left: 18rem;
    }

    .dividerAnimate {
        height: 230px;
    }
}