.dateSelectorSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centre les enfants horizontalement */
    margin-top: 40px;
}

.dateSelectorDescription {
    font-size: 0.9rem;
    margin-top: 20px;
    margin-left: 5px;
    text-align: left;    /* Alignement à gauche */
    width: 100%;    /* Assurez-vous qu'il prend la largeur complète */
}


.buttonConfirmer {
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
}

.buttonConfirmer:hover {
    background-color: #5465ff;
    /* hover:bg-blue-700 */
}

.buttonConfirmer:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
    /* focus:shadow-outline */
}

.buttonContainer {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: 10px
}

.dateTitle {
    text-align: center;
    font-weight: bold;
}