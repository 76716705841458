/* NosServicesSection.css */

/* Base styles for the services section */
.nos-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Adjust height to account for header */
  height: calc(100vh - 60px); /* Assuming header height is 60px */
  padding-top: 60px; /* Add padding to prevent content from being hidden by the header */
  scroll-snap-align: start;
}

.service-icon {
  width: 80px; /* Default size for icons */
  transition: transform 0.3s ease;
}

.iconZoom:hover {
  transform: scale(1.1);
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 50%;
  margin: 20px 0;
}

/* Text styles */
.text-gray-500 {
  color: #777; /* Example for text color */
}

.text-gray-700 {
  color: #555; /* Example for slightly darker text */
}

/* Responsive adjustments with media queries */
@media (max-width: 415px) {
  .nos-services {
      height: auto; /* Use auto height on small screens */
      padding-top: 90px; /* Ensure padding is still applied */
  }

  .nos-services h1 {
      font-size: 24px; /* Smaller title font size for very small devices */
  }

  .nos-services h2 {
      font-size: 18px; /* Smaller subtitle font size */
  }

  .grid {
      grid-template-columns: 1fr; /* Stack items vertically on very small screens */
      width: 90%; /* Increase width to use more space */
  }

  .service-icon {
      width: 50px; /* Smaller icons for small screens */
  }
}

@media (min-width: 416px) and (max-width: 599px) {
  .nos-services {
      height: auto; /* Use auto height on small screens */
      padding-top: 95px; /* Ensure padding is still applied */
  }

  .grid {
      grid-template-columns: 1fr; /* Use 2 columns for slightly larger phones */
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .nos-services {
    height: auto; /* Use auto height on small screens */
    padding-top: 95px; /* Ensure padding is still applied */
}

  .grid {
      width: 90%; /* Adjust grid width on small devices */
      padding-bottom: 20px;
  }

  .middle-column {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .nos-services h1 {
      font-size: 32px; /* Larger font size for tablets */
      padding-top: 95px;
  }

  .nos-services h2 {
      font-size: 24px; /* Adjust subtitle size for readability */
  }

  .grid {
    width: 90%; /* Adjust grid width on small devices */
    padding-bottom: 20px;
}

  .middle-column {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1200px) and (max-width: 1974px) {
  .nos-services {
    padding: 1% 15%; /* Provide some horizontal padding on large screens */
}
  .scaled  .nos-services {
    padding-top: 0.5%;
    padding-bottom: 0%;
    padding-right: 5%;
    padding-left: 5%;
    min-height: 100vh;
  }

.nos-services h1 {
  font-size: 2rem; /* Larger font size for tablets */
  padding-top: 95px;
  margin-bottom: 10px
}

.nos-services h2 {
font-size: 1.1rem; /* Adjust subtitle size for readability */
}

.grid {
    width: 85%; /* Optimal grid width for larger screens */
}

.service-icon {
    width: 50px; /* Larger icons for better visibility on large screens */
}

.middle-column {
  display: flex;
  align-items: center;
  justify-content: center;
}
}

@media (min-width: 1975px) and (max-width: 2559px) {
  .nos-services {
    padding: 5% 15%; /* Provide some horizontal padding on large screens */
}

.nos-services h1 {
  font-size: 2.5rem; /* Larger font size for tablets */
  padding-top: 95px;
}

.nos-services h2 {
font-size: 1.5rem; /* Adjust subtitle size for readability */
}

.grid {
    width: 85%; /* Optimal grid width for larger screens */
}

.service-icon {
    width: 50px; /* Larger icons for better visibility on large screens */
}

.middle-column {
  display: flex;
  align-items: center;
  justify-content: center;
}
}

@media (min-width: 2560px) {
  
  .nos-services h1 {
    font-size: 32px; /* Larger font size for tablets */
    padding-top: 95px;
}

.nos-services h2 {
  font-size: 24px; /* Adjust subtitle size for readability */
}
  .nos-services {
      padding: 10% 15%; /* Provide some horizontal padding on large screens */
  }

  .grid {
      width: 60%; /* Optimal grid width for larger screens */
  }

  .service-icon {
      width: 80px; /* Larger icons for better visibility on large screens */
  }

  .middle-column {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}