.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    width: 100vw;  
}

.felicitations {
    color: #182125;
    font-weight: bold;
    font-size: 36px;
    margin-top: 40px;
    text-align: center;
}

.congratsP {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}

.mainContainer {
  min-height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expiredSessionContainer {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;    /* Center align items */
    justify-content: center; /* Vertically center content */
    background-color: #f8d7da; /* Light red background for error indication */
    border: 1px solid #f5c6cb;  /* Slightly darker red border */
    color: #721c24;            /* Dark red text for high contrast */
    padding: 30px;             /* Add some padding */
    margin: 50px auto;         /* Center container on the page */
    width: 80%;                /* Adjust width according to the page layout */
    max-width: 600px;          /* Limit max-width for readability */
    border-radius: 10px;       /* Rounded corners */
    text-align: center;        /* Center text */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for some depth */
  }
  
  .expiredSessionContainer h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .expiredSessionContainer p {
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
  
  .expiredSessionContainer a {
    color: #721c24;           /* Keep the link color consistent with the text */
    text-decoration: underline; /* Underline to differentiate as a link */
  }
  
  .expiredSessionContainer a:hover {
    color: #5a171a;           /* Slightly darker color on hover */
  }
  
  .expiredSessionContainer .button {
    background-color: #d9534f; /* Bootstrap-like red button color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .expiredSessionContainer .button:hover {
    background-color: #c9302c; /* Darker red on hover */
  }

@media (max-width: 415px) {

    .felicitations {
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 0.985rem;
        padding: 0 20px;
    }

  }
  
  @media (min-width: 416px) and (max-width: 599px) {

    .felicitations {
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 0.985rem;
        padding: 0 20px;
    }
  }
  
  @media (min-width: 600px) and (max-width: 767px) {

    .felicitations {
        font-size: 1.5rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 0.985rem;
        padding: 0 20px;
    }

  }
  
  @media (min-width: 768px) and (max-width: 1199px) {


    .felicitations {
        font-size: 2rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 1.2rem;
        padding: 0 20px;
    }


  }
  
  @media (min-width: 1200px) and (max-width: 1974px) {

    .felicitations {
        font-size: 2rem;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .congratsP {
        font-size: 1.2rem;
        padding: 0 20px;
    }

  }
  
