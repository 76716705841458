.groomRoomSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px auto; /* Centrer la section */
    max-height: 500px;
    width: 80%; /* Ajustez selon la largeur désirée */
}

.columnsContainer {
  display: flex;
  width: 100%;
  gap: 20px; /* Espace entre les colonnes */
  margin-top: 25px;
}

.column {
  flex: 1; /* Les colonnes prennent un espace équivalent */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid #ccc;
  border-radius: 5px; */
  overflow-y: auto; /* Permettre le défilement individuel */
  max-height: 430px; /* Ajustez selon la hauteur désirée */
  margin-top: 65px;
  margin-bottom: 15px;
}

.columnp {
  margin-bottom: 16px; /* Ajustez la valeur selon vos besoins */
  margin-left: 16px;
  font-size: 0.9rem;
  text-align: left;
}

.columnh2 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.divider {
  width: 1px;
  border-left: 1px dotted #2d004b;
}

.roomConfirmationContainer {
  display: flex;
  align-items: center;
  justify-content: center; /* ou 'flex-start' pour aligner à gauche */
  gap: 20px; /* Espace entre les boutons */
}

.confirmationWithError {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}

.buttonConfirmer {
  background-color: #2d004b;    /* bg-blue-500 */
  color: #ffffff;    /* text-white */
  font-weight: bold;    /* font-bold */
  padding: 8px 16px;    /* py-2 px-4 */
  border-radius: 4px;    /* rounded */
  outline: none;    /* focus:outline-none */
  box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
  transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
  margin-top: 20px;
}

.buttonConfirmer:hover {
  background-color: #5465ff;
  /* hover:bg-blue-700 */
}

.buttonConfirmer:focus {
  box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
  /* focus:shadow-outline */
}

.buttonContainer {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-left: 10px
}

.selectStyle {
  padding: 10px; /* Ajoutez du padding autour du texte */
  border-radius: 5px; /* Bords arrondis */
  border: 1px solid #ccc; /* Couleur de la bordure par défaut */
  outline: none; /* Supprime l'outline par défaut lors de la sélection */
}

.selectStyle:focus {
  border-color: #5465ff; /* Couleur de la bordure quand le select est cliqué */
}