@media screen and (max-width: 1921px) and (min-width: 1001px) {
    .paymentCalculatorSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 3px;
        position: relative;
        height: 45vh; 
        width: 100%; 
        margin: auto;
        overflow: auto;
        justify-content: center;
    }
  }

.paymentCalculatorSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    padding: 3px;
    position: relative;
    height: 45vh; 
    width: 100%; 
    margin: auto;
    overflow: auto;
    justify-content: center;

}

.navDotsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centrer les boutons horizontalement */
    gap: 5px;
    position: absolute;
    left: 1px;
    /* ou ajustez selon le besoin */
    top: 50%;
    transform: translateY(-50%);
    z-index: 8999;
}

.navDot {
    border-width: 1px;
    width: 20px; /* Taille réduite, ajustez selon vos besoins */
    height: 20px; /* Taille réduite, ajustez selon vos besoins */
    z-index: 8999;
}

.leftAlignedContent {
    text-align: left;
    width: 100%;
}

.sectionsContainer {
    transition: transform 0.5s ease-in-out;
    /* height: 100%; */
    /* Nouveau */
    display: flex;
    /* Nouveau */
    flex-direction: column;
    /* Nouveau */
}

/* .element {
    height: 100vh;
    /* Nouveau 
} */

.hidden {
    display: none;
}

.paymentCalculatorSectionH2 {
    font-size: 20px;
    margin-bottom: 20px;
}

.navButton {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
}

.navButtonTopLeft {
    top: 10px;
    left: 10px;
}

.navButtonBottomRight {
    bottom: 10px;
    right: 10px;
}

.navButton:hover {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    scale: 1.1;
    color: #2d004b;
}

/* Séparateur entre les deux sélecteurs de date */
.date-selectors-container>div:first-child {
    margin-right: 20px;
}