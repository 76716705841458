.creer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% de la hauteur de la fenêtre de visualisation */
}

.creer-container h1 {
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 50px;
}

.input-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrage vertical */
  align-items: center; /* Centrage horizontal */
  margin-bottom: 20px;
}

.input-container label,
.input-container input {
  display: block;
  margin-bottom: 8px; /* Espacement entre le label et l'input */
}

.input-container input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style pour le bouton, similaire à celui de Tailwind */
.creer-button {
  background-color: #2d004b;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease-in-out;
}

.creer-button:hover {
  background-color: #5465ff;
}
