@media screen and (max-width: 1921px) and (min-width: 1001px) {

  .reactQuillContainer {
    max-width: 85% !important;
    min-width: 65% !important;
    height: auto !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
  }

  .CustomMailDescription {
    margin-left: 160px !important;    
  }

  .QuillEditorDescription {
    font-style: italic;
    margin-bottom: 10px;
    font-size: .75rem
  }

  .confirmButtonContainer {
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 10px; /* Ajouter une marge en haut si nécessaire */
    font-size: .8rem
  }
  
}
  
.editorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Autres styles nécessaires */
}

.CustomMailSection {
  display: flex; /* Utilisation de Flexbox */
  align-items: center; /* Centrage vertical */
  justify-content: flex-start; /* Espacement entre les éléments */
  flex-direction: column;
}

.reactQuillContainer {
  flex-grow: 1; /* Permet à ReactQuill de prendre l'espace disponible */
  max-width: 150%;
  min-width: 100%;
  margin: 0 10px; 
}

.CustomMailDescription {
  margin-left: 50px;
  text-align: left;
  align-self: flex-start;  
}

.titleWithButton {
  display: flex; /* Alignement horizontal du bouton et du titre */
  align-items: center; /* Centrage vertical des éléments */
  justify-content: center; /* Centrage horizontal des éléments dans le conteneur */
  width: 100%; /* Assurez-vous que le conteneur prend toute la largeur disponible pour le centrage */
}

.QuillEditorTitre {
  font-weight: bold;
  margin-left: 8px; /* Espacement entre le bouton et le titre, ajustez selon vos besoins */
}

.QuillEditorDescription {
  font-style: italic;
  margin-bottom: 10px;
}

.toggleButton {
  /* Styles communs aux boutons */
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  font-size: 18px;
  margin: 0 5px;
  z-index: 1000;
}

.toggleButtonLeft {
  left: 50px;
}

.toggleButtonRight {
  right: 50px;
}

.confirmButtonContainer {
  background-color: #2d004b;    /* bg-blue-500 */
  color: #ffffff;    /* text-white */
  font-weight: bold;    /* font-bold */
  padding: 8px 16px;    /* py-2 px-4 */
  border-radius: 4px;    /* rounded */
  outline: none;    /* focus:outline-none */
  box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
  transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
  margin-top: 20px; /* Ajouter une marge en haut si nécessaire */
  /* Autres styles nécessaires */
}

.confirmButtonContainer:hover {
  background-color: #5465ff;
}

@media screen and (max-width: 768px) {
  .toggle-button {
    display: none;
  }
}

.flipIcon {
  transition: transform 0.5s ease; /* Durée et style de transition */
  display: inline-block; /* Nécessaire pour appliquer la transformation */
  margin-right: 10px;
}

.flipIcon:hover {
  transform: rotateY(180deg); /* Retourner l'icône */
}

.infoButton {
  cursor: pointer;
  background: none; /* Enlever le fond par défaut des boutons */
  border: none; /* Enlever la bordure par défaut des boutons */
  padding: 0; /* Enlever le padding par défaut des boutons */
  display: flex;
  align-items: center; /* Alignement vertical du contenu du bouton */
}