@import '~leaflet-geosearch/dist/geosearch.css';

.venue-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative; /* Make venue-container the relative container */
  }

.map-wrapper {
    position: relative; /* Relative positioning for absolute positioning context */
    width: 100%;
    height: 100%; /* Ensure the map takes full available space */
}  

.venue-header {
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
  }

  .button-suivant {
    position: absolute; /* Change to absolute */
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    z-index: 1000;
  }

.button-precedent {
    position: absolute;
    bottom: 60px;
    /* Modifié pour rapprocher du bas */
    left: 18%;
    transform: translateX(-50%);
    z-index: 1000;
}

.map-container {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.contact-link {
    color: blue;
    text-decoration: underline;
}

.buttonS {
    background-color: #2d004b;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin-top: 1vh;
  }

.buttonS:hover {
    background-color: #5465ff;
    color: #ffffff;
}

.buttonS:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
}


@media (min-width: 768px) and (max-width: 1199px) {
    .button-suivant {
        bottom: 10px;
    }
}

@media (min-width: 1200px) and (max-width: 1974px) {
    .button-suivant {
        bottom: 15px;
    }
}

@media (min-width: 1975px) {
    .button-suivant {
        bottom: 360px;
    }
}

