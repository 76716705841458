.galleryImages img {
    height: auto;
    /* Ajustez selon la taille souhaitée */
    height: auto;
}

.galleryImages {
    flex: 1;
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 20px;
    scroll-behavior: smooth; /* Ajoute un effet de défilement fluide */
}

.galleryContainer {
    display: flex;
    align-items: center;
    background-color: #182125;
    padding: 12px 0;
}

.galleryImage {
    flex: none;
    height: auto; /* Préserve le ratio hauteur/largeur */
    max-height: 200px; /* Hauteur maximale pour toutes les images */
    object-fit: contain; /* Assure que toute l'image est visible */
    cursor: pointer; /* Change le curseur */
    transition: transform 0.2s ease;
}

.galleryImage:hover {
    transform: scale(1.05); /* Légère augmentation de taille */
    /* Ou vous pouvez ajouter une bordure, changer l'opacité, etc. */
}

.scrollArrow {
    cursor: pointer;
    user-select: none;
    font-size: 24px;
    /* Ajustez la taille selon vos besoins */
    padding: 10px;
    color: #333;
    /* Couleur de la flèche */
    /* background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%; */
}

.scrollArrow.left {
    left: 0;
}

.scrollArrow.right {
    right: 0;
}