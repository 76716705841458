.monCompte {
  display: flex; /* de 'flex' */
  /* /*justify-content: center;  de 'justify-center'  */
  align-items: center; /* de 'items-center' */
  min-height: 100vh;  /*de 'h-screen' */
  padding-bottom: 50px;
  /* Ajoutez ici les autres styles que vous souhaitez intégrer */
}

.cursorPointer {
  cursor: pointer;
}

.sidebar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas:
    "dashboard profile"
    "referral referral";
  grid-gap: 10px;
  align-items: center;
  justify-items: center;
  background-color: #ffffffd3;
  /* margin-left: 10px; 
    padding: 15px;  */
}

.card {
  width: calc(250px + 2vw);  /* Ajouté 2vw */
  height: calc(150px + 2vh);  /* Ajouté 2vh */
  margin: 10px;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.1);
}

.cardVeil {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  transition: background 0.3s ease-in-out;
  /* Ajout d'une transition */
  border-radius: 12px;
}

.card:hover .cardVeil {
  background: rgba(0, 0, 0, 0);
  /* Retrait du voile lors du survol */
}

.cardText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  /* Texte en noir par défaut */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s ease-in-out;
  /* Ajout d'une transition */
  border-radius: 12px;
}

.card:hover .cardText {
  color: white;
  /* Texte en blanc lors du survol */
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.8);
  /* Contour gris foncé */
}

.dashboardCard {
  background-image: url('./assets/Images/MesProjets_Card.jpg');
  grid-area: dashboard;
  /* height: 100%;
  width: 100%; */
}

.profileCard {
  background-image: url('./assets/Images/MonProfil_Card.jpg');
  grid-area: profile;
  /* height: 100%;
  width: 100%; */
}

.referralCard {
  background-image: url('./assets/Images/MesParrainages_Card.jpg');
  grid-area: referral;
  /* height: 100%;
  width: 100%; */
}

/* .mon-compte.flex {
  position: relative;
  transition: all 0.5s ease-in-out;
} */

.sidebarShrink {
  position: absolute;
  left: 0;
  transition: all 0.5s ease-in-out;
}

.verticalLine {
  width: 1px;
  background-color: black;
  position: absolute;
  height: 100%;
  left: calc(12.5% - 1px);
}

.reducedSidebar {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  transition: all 0.5s ease-in-out;
}

.reducedCard {
  width: calc(10vw - 10px);
  /* Ajustez les valeurs selon vos besoins */
  height: calc(6vw - 10px);
  /* Ajustez les valeurs selon vos besoins */
  margin-left: calc(11% + 10px);
  transition: all 0.5s ease-in-out;
}

.sidebarReduced {
  grid-template-areas:
    "dashboard"
    "profile"
    "referral";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
}

.returnButton {
  position: fixed;
  /* Fixed à l'écran */
  top: 70px;
  /* En dessous du header (50px) + un peu d'espace (10px) */
  left: calc(250px + 30px);
  /* À droite de la sidebar (200px) + un peu d'espace (10px) */
  z-index: 2;
  /* pour s'assurer qu'il apparaît au-dessus des autres éléments */
}

.returnIcon {
  color: #333;
  /* Couleur initiale */
  transition: all 0.3s ease;
}

.returnIcon:hover {
  transform: scale(1.1);
  transition: all 0.2s ease;
  color: #5465ff;
}

.modalButton {
  background-color: #2d004b;
  color: #ffffff; 
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;    /* rounded */
  outline: none;    /* focus:outline-none */
  box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
  transition: background-color 0.2s, box-shadow 0.2s;
  margin-top: 8px;
  margin-bottom: 8px;
}

.modalButton:hover {
  background-color: #5465ff;
}