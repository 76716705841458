/* AboutSection.css */

/* Base styles for the about section */
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Adjust height to account for header */
  height: calc(100vh - 60px); /* Assuming header height is 60px */
  padding-top: 60px; /* Add padding to prevent content from being hidden by the header */
}

.grid-about {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 50%;
  margin: 20px 0;
}

/* Text styles */
.text-gray-500 {
  color: #777; /* Light gray text */
}

.text-gray-700 {
  color: #555; /* Darker gray text */
}

.h2-color {
  color: #000
}

/* Responsive adjustments with media queries */
@media (max-width: 415px) {
  .about-section {
      height: auto; /* Use auto height on small screens */
      padding: 90px 10px 10px 10px; /* Adds padding to prevent text from touching the edges and overlap */
  }

  .grid-about {
      grid-template-columns: 1fr; /* Stack items vertically on very small screens */
      width: 100%; /* Increase width to use more of the available space */
  }

  .about-section h1, .about-section h2 {
      font-size: 16px; /* Even smaller text size for very small devices */
      font-weight: 500;
  }
}

@media (min-width: 416px) and (max-width: 599px) {
  .about-section {
      height: auto; /* Use auto height on small screens */
      padding-top: 95px; /* Ensure padding is still applied */
  }

  .grid-about {
      grid-template-columns: 1fr; /* Continue to stack items vertically */
      width: 90%; /* Adjusted width for small screens */
  }

  .about-section h1, .about-section h2 {
      font-size: 18px; /* Adjust font size for small devices */
      font-weight: 500;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .about-section {
    height: auto; /* Use auto height on small screens */
    padding-top: 75px; /* Ensure padding is still applied */
}

  .grid-about {
      grid-template-columns: repeat(3, 1fr); /* Use 2 columns for larger phones */
      width: 90%
  }

  .about-section h1, .about-section h2 {
    font-size: 18px; /* Adjust font size for small devices */
    font-weight: 500;
}
}

@media (min-width: 768px) and (max-width: 1199px) {
  .about-section {
    min-height: 100vh; /* Use auto height on small screens */
    padding-top: 200px; /* Ensure padding is still applied */
}

  .grid-about {
      grid-template-columns: repeat(3, 1fr); /* Use 2 columns for larger phones */
      width: 90%
  }

  .about-section h1, .about-section h2 {
      font-size: 24px; /* Adjust font size for tablets */
      font-weight: 500;
  }

}

@media (min-width: 1200px) {
  .about-section {
    min-height: 100vh; /* Use auto height on small screens */
    padding-top: 15%; /* Ensure padding is still applied */
}

  .scaled .about-section {
    padding-top: 10%;
    min-height: 100vh;
  }

  .grid-about {
      grid-template-columns: repeat(3, 1fr); /* Use 2 columns for larger phones */
      width: 60%
  }

  .about-section h1, .about-section h2 {
      font-size: 28px; /* Set font size for large desktops */
      font-weight: 500;
  }
}