.cookie-banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;  /* Utilisez un pourcentage de la largeur de la fenêtre ou définissez une largeur fixe en px */
  max-height: 80vh;  /* Hauteur maximale en pourcentage de la hauteur de la fenêtre */
  overflow-y: auto;
  background-color: white;
  color: black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.514);
  border-radius: 8px;
  padding: 20px;
  z-index: 1000;
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  word-wrap: break-word;
}

/* Styles pour la vue détaillée */
.cookie-banner.detailed-view {
  /* max-width: 20%; supprimé */
  height: auto;
  max-height: auto;
  overflow-y: auto;
  overflow-x: auto;
  word-wrap: break-word;
  white-space: normal; /* ajouté */
  /* ajoutez d'autres styles spécifiques à la vue détaillée ici */
}

.cookie-banner h1 {
  font-size: 24px;
}

.cookie-banner .roboto {
  font-size: 14px; /* Réduire la taille du texte */
}

.cookie-banner.detailed-view .roboto {
  font-size: 13px;
}

.cookie-banner h2 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bolder;
}

.cookie-banner label {
  display: block;
  margin-bottom: 10px;
}

.cookie-banner-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cookie-banner button {
  background-color: #2d004b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 20px;
}

.button-container {
  display: flex;
  justify-content: space-around; /* ou space-between, selon vos préférences */
  flex-wrap: nowrap;
  align-items: center;
}

.cookie-banner button:hover {
  background-color: #5465ff; /* Couleur de fond lorsque la souris passe dessus */
}

.cookie-banner button:last-child {
  margin-right: 0;
}

.clickable-text {
  text-decoration: underline;
  cursor: pointer;
}

.cookie-label {
  font-weight: bold;
  color: #333; /* Couleur du texte */
  font-size: 16px; /* Taille de la police */
  margin-right: 10px; /* Espacement à droite du label */
  margin-top: 20px
}

.cookie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* couleur d'arrière-plan semi-transparente */
  z-index: 999; /* s'assurer qu'il est en dessous de la bannière */
}

.cookie-option {
  display: flex;
  flex-direction: column; /* Organise les enfants en colonne */
  align-items: flex-start; /* Aligne les enfants à gauche */
}

.cookie-header {
  display: flex;
  justify-content: space-between; /* Crée de l'espace entre le label et le switch */
  width: 100%; /* Utilise toute la largeur disponible */
  align-items: baseline;
}

.cookie-label {
  /* Vous pouvez ajuster la largeur pour s'assurer que le commutateur est bien aligné à droite */
  /* width: 80%; */
  flex-grow: 1;
  margin-top: 10px;
}

.cookie-description {
  /* Si vous souhaitez ajuster l'alignement vertical du texte de description, vous pouvez le faire ici */
  margin-top: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Couleur de fond lorsque désactivé */
  transition: 0.4s;
  border-radius: 34px; /* Pour rendre les bords arrondis */
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 26px; /* Taille du cercle interne */
  width: 26px; /* Taille du cercle interne */
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%; /* Pour rendre le cercle parfait */
}

.switch-checkbox:checked + .switch-slider {
  background-color: green; /* Couleur de fond lorsque activé */
}

.switch-checkbox:checked + .switch-slider:before {
  transform: translateX(26px); /* Déplacer le cercle interne à droite */
}

@media (max-width: 415px) {
  .cookie-banner {
    width: 75% /* Smaller font size for smaller screens */
  }

}

@media (max-width: 599px) and (max-width: 599px) {
  .cookie-banner {
    width: 65% /* Smaller font size for smaller screens */
  }

}

/* Small Devices, Phones (600px and up) */
@media (min-width: 600px) and (max-width: 767px) {
  .cookie-banner {
    width: 55% /* Smaller font size for smaller screens */
  }

}

/* Medium Devices, Tablets (768px and up) */
@media (min-width: 768px) and (max-width: 1199px) {
  .cookie-banner {
    width: 45% /* Smaller font size for smaller screens */
  }
}

/* Large Desktops (1200px and up) */
@media (min-width: 1200px) and (max-width: 2559px) {
  .cookie-banner {
    width: 45% /* Smaller font size for smaller screens */
  }
}

@media (min-width: 2560px) {
  .cookie-banner {
    width: 30% /* Smaller font size for smaller screens */
  }
}