.customModal {
    background-color: white;
    padding: 20px;
    max-width: 500px;
    margin: 100px auto;
    border-radius: 8px;
}

.customModalOverlay {
    background: rgba(0, 0, 0, 0.75);
}

.modalTitle {
    font-size: 1.5rem;
    font-weight: 600;
}

.modalButtons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.saveButton,
.cancelButton {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
}

.saveButton {
    background-color: #596ffc;
    color: white;
}

.saveButton:hover {
    background-color: #2d004b;
}

.cancelButton {
    background-color: #ccc;
    color: #000;
}

.cancelButton:hover {
    background-color: #000;
    color: #fff;
}

.customModalInput {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.53333;
    transition: border-color 0.3s ease;
    margin: 10px 0;
}

.toggleCheckbox {
    display: none;
}

.toggleCheckboxGroom {
    display: none;
}

.toggleSwitch {
    width: 50px;
    height: 25px;
    background: #2d004b;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background 0.3s ease;
}

.toggleCheckbox:checked+.toggleSwitch {
    background: rgb(99, 102, 241);
}

.toggleCheckboxGroom:checked+.toggleSwitch {
    background: rgb(211, 10, 60);
}

.toggleSwitch::after {
    content: '';
    width: 23px;
    height: 23px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: transform 0.3s ease;
}

.toggleCheckbox:checked+.toggleSwitch::after {
    transform: translateX(25px);
}


.toggleCheckboxGroom:checked+.toggleSwitch::after {
    transform: translateX(25px);
}

.pricingToggleContainer {
    display: flex;
    justify-content: flex-start;
    padding: 15px 0;
}

.modalButtonsRoom {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
}