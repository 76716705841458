@keyframes fast-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .fast-spin {
    animation: fast-spin 0.5s linear;
  }