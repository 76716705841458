@media screen and (max-width: 1921px) and (min-width: 1001px) {
  .selectedItem {
      text-align: center;
      font-size: 0.785rem;
      /* Réduire la taille du texte */
      width: 80%;
      margin-left: 20px;
      /* margin-top: 10px; */
  }
  .controls {
    display: inline-flex;
    justify-content: space-between;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.514);
    border-radius: 10px;
    max-width: 500px;
    padding: 12px;
    margin: auto;
    overflow: hidden;
    position: relative;
    font-size: 0.9rem;
  }
  
}

.controlsContainer {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;
  margin-bottom: 25px;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.514);
  border-radius: 10px;
  max-width: 500px;
  padding: 12px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  min-width: 120px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 700;
  padding: 10px;
  transition: color 0.5s ease;
}

.segment.active label {
  color: #ffffff;
}

.controls.disabled {
  opacity: 0.5; /* Rend le contrôle plus pâle */
  pointer-events: none; /* Empêche toute interaction */
  /* Autres styles pour indiquer l'état désactivé */
}

.controls::before {
  content: "";
  background: #5465ff;
  border-radius: 8px;
  width: var(--highlight-width);
  transform: translateX(var(--highlight-x-pos));
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}