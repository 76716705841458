/* Footer.module.css */
.footer {
    padding: 20px;
    text-align: center;
    background-color: #182125;
    color: #ffffff
  }

.footerContainer {
  z-index: 20;
}
  
  .divider {
    margin-bottom: 10px;
  }
  
  .links {
    list-style: none;
    padding: 0;
    cursor: pointer;
  }
  
  .links li {
    display: inline;
    margin-right: 20px;
  }
  
  .socialIcons {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}