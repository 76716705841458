/* Messages de succès et d'erreur */
.success-message,
.error-message {
  font-weight: bold;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}

/* Styles du container */
.grid-container {
  display: grid;
  place-items: center;
  max-width: 100%;
  margin: auto;
}

.table-container {
  max-width: 100%;
  margin: auto;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: auto;
  transition: height 0.3s ease-in-out;
  position: relative;
}

.table-container.expanded {
  height: auto;
}

.table-container.collapsed {
  height: 50vh; 
}

.expandButton {
  background-color: #2d004b;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;  
  height: 20px;
  border: 1px solid #ccc;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.expandButton:hover {
  background-color: #5465ff;
}

.table-and-button-container {
  position: relative;
}

/* Styles de table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}


th,
td {
  border: 1px solid #ccc;
  white-space: normal;
}

th {
  position: sticky;
  top: 0;
  background-color: #182125;
  font-weight: bold;
  color: #ffffff;
  z-index: 1;
}


/* Styles des selects */
select {
  background-color: transparent;
  border: 1px solid #ccc;
}

/* Styles de bouton */
.nouvelle-ligne-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.nouvelle-ligne-button:hover {
  background-color: #45a049;
}

/* Styles pour le contenu centré */
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.instruction-container {
  margin-top: 150px;
  margin-bottom: 50px;
  font-size: 15px;
  max-width: 50%;
}


.instruction-container h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.ajouter-ligne-button-container {
  margin-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.fixed-button {
  position: fixed;
  bottom: 20px;
  left: 18%;
  transform: translateX(-50%);
  z-index: 1000;
}

.sauvegarder-button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.buttons {
  background-color: #212866;
  color: #ffffff;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 0 0 0 transparent;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin-top: 5px;
  margin-left: 30px;
}

.buttons:nth-of-type(2) {
  margin-left: auto;
}


.buttons:hover {
  background-color: #5465ff;
}

.buttons:focus {
  box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
}

.buttonGroup {
  background-color: #212866;
  color: #ffffff;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 0 0 0 transparent;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin-top: 5px;
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.buttonGroup:nth-of-type(2) {
  margin-left: auto;
}


.buttonGroup:hover {
  background-color: #5465ff;
}

.buttonGroup:focus {
  box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
}

.buttonSave {
  background-color: #53885c;    
  color: #ffffff;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 0 0 0 transparent;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin-top: 5px;
  margin-left: 30px;
}

.buttonSave:hover {
  background-color: #326436;
}

.buttonSave:focus {
  box-shadow: 0 0 0 3px rgba(1, 211, 12, 0.5);
}

.title-modal {
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 2svh;
}

.listTable {
  margin-bottom: 5px;
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;  /* This will make the container take the full viewport height */
  width: 100vw;   /* This will make the container take the full viewport width */
}

.iconGroup {
  margin-right: 7px;
  display: initial;
  vertical-align: middle;
}