@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px); /* Default bounce amplitude */
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  @keyframes bounceSmall {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px); /* Reduced bounce amplitude for small screens */
    }
    60% {
      transform: translateY(-7.5px);
    }
  }
  
  .faBounce {
    animation: bounce 2s infinite;
  }
  
  @media (max-width: 768px) {
    .faBounce {
      animation: bounceSmall 2s infinite;
    }
  }
  