/* IntroSection.css */

/* .intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh; /* Full height of the viewport 
  font-family: 'Roboto', sans-serif; /* Ensure Roboto font is linked in your HTML 
  letter-spacing: 0.05em;
  color: #ffffff /* Adjust letter spacing for better readability 
} */

.intro-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.05em;
  color: #ffffff;
  overflow: hidden; /* Prevent overflow */
  position: relative; /* Needed for absolute positioning of parallax elements */
}

@keyframes slideIn {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.parallax-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 110vw; /* Use viewport width */
  height: 110vh; /* Use viewport height */
  overflow: hidden; /* Prevent content from overflowing */
  perspective: 1px; /* For the parallax effect */
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Cover the container fully */
  height: 100%; /* Cover the container fully */
  background-position: center;
  background-size: cover;
  will-change: transform;
}

.background {
  background-image: 
    radial-gradient(#1a182578,#06060600), /* Semi-transparent overlay */
    url('./assets/Images/Wallpaper Home V 1-1.png'); /* Background image */
  background-size: cover; /* Cover the entire element */
  background-position: center; /* Center the background */
  background-repeat: no-repeat; /* Prevent repeating */
}

.overlay {
  background-image: url('./assets/Images/Calque\ Lucioles.png');
  background-size: cover; /* Cover the entire element */
  background-position: center; /* Center the background */
  background-repeat: no-repeat; /* Prevent repeating */
}

/* .logo-intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.logo-intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10; /* Ensure it's on top of the background layers */
  position: relative; /* Make it relative for z-index to take effect */
  padding: 20px; /* Add some padding if necessary */
}

.yg-logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15; 
  margin-bottom: 20px; 
  opacity: 0.85;
}

.yg-logo img {
  width: 250px; 
  height: auto;
}

.introH1-container {
  position: relative;
  display: inline-block; 
  padding: 20px; 
  background: radial-gradient(circle at center, rgba(24, 33, 37, 1) 0%, rgba(26, 34, 44, 0.75) 33%, rgba(32, 35, 64, 0) 100%);
  opacity: 0.7; 
  border-radius: 50%;
}

.introH1 {
  position: relative;
  font-weight: bold;
  font-size: 2.5rem; 
  padding-bottom: 15px;
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  margin: 0.5rem 0;
  z-index: 1; 
  /* -webkit-text-stroke: 1px #a97679; 
  text-stroke: 1px #a97679;  */
}

.underline {
  text-decoration: none;
}

.line-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px; 
}

.underline::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 6px;
  width: 100%;
  background-color: #2d004b;
  opacity: 0.7;
  animation-delay: 500;
  animation: slideIn 3s ease-in-out;
}

.scrolling-text {
  height: 2.5rem; 
  overflow: hidden; 
}

.scrolling-item {
  position: absolute; 
  width: 100%; 
  top: 0; 
  left: 0; 
  opacity: 0; 
  animation: scrollText 9s ease-in-out infinite; 
}

.scrolling-item:nth-child(1) {
  animation-delay: 0s; 
}

.scrolling-item:nth-child(2) {
  animation-delay: 3s; 
}

.scrolling-item:nth-child(3) {
  animation-delay: 6s; 
}

@keyframes scrollText {
  0% {
    opacity: 0; 
  }
  10%, 30% {
    opacity: 1; 
  }
  33.33%, 100% {
    opacity: 0; 
  }
}

.intro-text {
  font-size: 1.2rem; 
  margin: 20px 0; 
  max-width: 600px; 
  line-height: 1.6;
  z-index: 2;
  /* -webkit-text-stroke: 0.3px #a97679; 
  text-stroke: 0.3px #a97679;  */
}

.button-next-section {
  z-index: 150;
}

/* Media Queries for Responsive Font Sizes */

/* Extra Small Devices, Phones (less than 600px) */
@media  (max-width: 350px) {
  .introH1 {
    font-size: 0.895rem;
    padding-bottom: 8px;
  }

  .underline::after {
    height: .5vh;
    bottom: -1px;
  }

  .scaled .introH1 {
    font-size: 0.895rem;
  }

  .scaled .underline::after {
    height: .6vh;
  }

  .intro-text {
    font-size: 0.8rem;
  }
}


@media (max-width: 415px) and (min-width: 351px) {
  .introH1 {
    font-size: 0.92rem;
    padding-bottom: 8px; 
  }

  .underline::after {
    height: .5vh;
    bottom: -1px;
  }

  .scaled .introH1 {
    font-size: 0.92rem;
  }

  .scaled .underline::after {
    height: .6vh;
  }

  .intro-text {
    font-size: 0.9rem;
  }

  .yg-logo img {
    width: 150px; 
    height: auto;
  }
}

@media (min-width: 416px) and (max-width: 599px) {
  .introH1 {
    font-size: 1.1rem;
    padding-bottom: 8px;
  }

  .underline::after {
    height: .5vh;
    bottom: -1px;
  }

  .scaled .introH1 {
    font-size: 1.1rem;
  }

  .scaled .underline::after {
    height: .6vh;
  }

  .intro-text {
    font-size: 0.875rem;
  }

  .yg-logo img {
    width: 150px;
    height: auto;
  }
}

/* Small Devices, Phones (600px and up) */
@media (min-width: 600px) and (max-width: 767px) {
  .introH1 {
    font-size: 1.5rem;
    padding-bottom: 8px;/* Smaller font size for smaller screens */
  }

  .underline::after {
    height: .5vh;
    bottom: -1px;
  }

  .scaled .introH1 {
    font-size: 1.5rem;
  }

  .scaled .underline::after {
    height: .6vh;
  }

  .intro-text {
    font-size: 1.2rem;
  }

  .yg-logo img {
    width: 150px; /* Adjust the size as needed */
    height: auto;
  }
}

/* Medium Devices, Tablets (768px and up) */
@media (min-width: 768px) and (max-width: 1199px) {
  .introH1 {
    font-size: 1.9rem; /* Larger font size for tablet screens */
  }

  .scaled .introH1 {
    font-size: 1.9rem;
  }

  .intro-text {
    font-size: 1.4rem;
  }

  .yg-logo img {
    width: 180px; /* Adjust the size as needed */
    height: auto;
  }
}

/* Large Desktops (1200px and up) */
@media (min-width: 1200px) {
  .introH1 {
    font-size: 2.3rem; /* Default size, redefined for clarity and customization */
  }

  .scaled .introH1 {
    font-size: 2.8rem;
  }

  .scaled .button-next-section {
    margin-top: 3rem;
  }

  .intro-text {
    font-size: 1.4rem;
  }

  .yg-logo img {
    width: 180px; /* Adjust the size as needed */
    height: auto;
  }

  /* High-DPI screens (scaled resolutions) */
  @media (min-resolution: 192dpi) {
    .introH1 {
      font-size: 3rem; /* Adjusted for high-DPI screens */
    }
  }
}

@media (min-width: 1440px) and (min-resolution: 2dppx), /* Detect 1440px wide screens with 2x density */
       (min-width: 1440px) and (-webkit-min-device-pixel-ratio: 2), /* Detect Retina screens */
       (min-resolution: 192dpi) { /* Detect HiDPI screens with 192dpi or higher */

  .scaled .yg-logo img {
    width: 150px; /* Increase size for HiDPI screens */
    height: auto; /* Maintain aspect ratio */
  }

  .scaled .introH1 {
    font-size: 2rem; /* Default size, redefined for clarity and customization */
  }
}

/* Adjust logo size for 1440x900 laptop screens specifically */
@media (min-width: 1200px) and (max-width: 1600px) and (min-height: 800px) and (max-height: 1000px) {
  .scaled .yg-logo img {
    width: 150px; /* Slightly larger for these screen sizes */
    height: auto;
  }
  .scaled .introH1 {
    font-size: 2rem; /* Default size, redefined for clarity and customization */
  }

  .underline::after {
    bottom: -15px;
  }
}

/* Additional Media Queries for Other Resolutions (if needed) */
@media (min-width: 1200px) and (max-width: 1439px) {
  .scaled .yg-logo img {
    width: 170px; /* Adjust size based on the screen width */
    height: auto;
  }
  .scaled .introH1 {
    font-size: 2rem; /* Default size, redefined for clarity and customization */
  }
  .underline::after {
    bottom: -15px;
  }
}

/* Further adjustments for High-DPI screens with different resolutions */
@media (min-width: 1440px) and (min-resolution: 3dppx) {
  .scaled .yg-logo img {
    width: 180px; /* Adjust size for 3x density screens */
    height: auto;
  }
  .scaled .introH1 {
    font-size: 2rem; /* Default size, redefined for clarity and customization */
  }
  .underline::after {
    bottom: -15px;
  }
}