.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modalContent {
    background: white;
    padding: 1em;
    border-radius: 1em;
    display: flex;
    flex-direction: column; /* oriente les éléments enfants verticalement */
    justify-content: center; /* aligne les éléments verticalement au centre */
    align-items: center; /* aligne les éléments horizontalement au centre */
  }
