/* Base styles for the header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 20;
    transition: all 0.5s ease;
    /* padding: 10px 20px; */
    top: 0;
    background-color: #182125;
  }

  .header-burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    z-index: 20;
    transition: all 0.5s ease;
    /* padding: 10px 20px; */
    top: 0;
    background-color: transparent;
  }
  
  .header-blur {
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  /* Container styles */
  .container {
    display: flex;
    justify-content: space-between; /* Horizontal alignment of children */
    align-items: center; /* Vertical alignment */
    width: 100%;
    height: 8.5svh; /* Adjust as needed for consistent height */
  }

  .container-burger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  nav ul {
    display: flex;
    list-style: none;
  }
  
  nav ul li {
    margin: 0 10px;
  }

  .header-link {
    /* color: #ffffff; White text color */
    text-decoration: none;
    font-size: 1.25rem;
    transition: color 0.3s ease;
  }

  /* .header-link-scroll {
    color: #182125;
    text-decoration: none;
    font-size: 1.25rem;
    transition: color 0.3s ease;
  } */

  .header-link:hover
  /*, .header-link-scroll:hover  */
  {
    color: #5465ff !important; /* Brand color on hover */
  }

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 23px;
  }  

  .bm-burger-bars {
    background: #5667b0;
  }
  
  /* .bm-cross-button {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 8px;
    top: 8px;
  } */

  /* .right .bm-burger-button {
    left: initial;
    right: 36px;
  }   */

  .bm-cross {
    background: #ffffff;
  }

  .bm-cross-large {
    background: #182125;
  }

  .bm-menu-wrap {
    height: 100vh !important; /* Ensure the menu takes the full height */
  }
  
  .bm-menu {
    background: #071510; /* Default color for small screens */
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    position: fixed;
    left: 0; /* Ensures menu starts from the left */
    top: 0; /* Aligns the menu to the top */
    height: 100%; /* Full height */
    width: 250px; /* Adjust width as needed */
    overflow-y: auto; /* Allows scrolling if content overflows */
  }

  .bm-menu-large {
    background: transparent; /* Transparent background for large screens */
    padding: 2.5em 1.4em 0;
    font-size: 1.14em;
    position: fixed;
    left: 0; /* Ensures menu starts from the left */
    top: 0; /* Aligns the menu to the top */
    height: 100%; /* Full height */
    width: 250px; /* Adjust width as needed */
    overflow-y: auto; /* Allows scrolling if content overflows */
  }
  
  .bm-item-list {
    display: flex;
    flex-direction: column;
    padding: 0.8em;
  }

  .bm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Adjust overlay color and transparency */
  }
  
  .header-link-burger {
    padding: 0.5em;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
    cursor: pointer;
  }
  
  .header-link-burger:hover {
    color: #5465ff !important;
  }

  .md:hidden {
    height: 100vh; /* Set the height of the burger menu container to 100% of the viewport height */
  }

  /* Separator styles */
  .header__separator {
    width: 1px;
    height: 20px;
    background-color: lightgray;
    margin: 0 10px;
  }
  
  /* Logo styles */
  .logo-yg-container {
    position: relative;
    width: 80px;
    height: 70px;
    /* align-content: center; */
  }
  
  /* .logo-yg, .logo-yg-hover {
    position: absolute;
    /* width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    transition: opacity 0.5s ease;
  } */
  
  .logo-yg {
    opacity: 1;
    width: 200%;
    height: 120%;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-size: contain;
    background-repeat: no-repeat; */
    /* background-image: url('./assets/icons/LOGO.png'); */
    /* opacity: 1; */
  }
  
  /* .logo-yg-hover {
    background-image: url('./assets/icons/Logo\ YG\ 2024\ Biseauté\ Dark\ Blue.png');
    opacity: 0;
  } */
  
  /* .logo-yg-container:hover .logo-yg-hover {
    opacity: 1;
  }
  
  .logo-yg-container:hover .logo-yg {
    opacity: 0;
  } */
  
  /* Login/Register button styles */
  .login-register {
    display: flex;
    align-items: center;
  }
  

  .login-register button,
.connexion-button {
  background-color: #5465ff;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  color: #ffffff; /* White text color */
  transition: color 0.3s ease;
  padding: 8px 16px;
  border-radius: 20px;
  padding: 4px 13px;
  border: none;
  border-radius: 35px;
}

.connexion-button {
  background-color: #5465ff; /* Brand color */
  color: white;
  border: none;
}

.login-register button:hover,
.connexion-button:hover {
  background-color: #2d004b;
  border: 1px solid #5465ff;;
}
    
  /* Dropdown menu styles */
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
  width: 150px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-menu.hidden {
  display: none; /* Hide menu */
}
  
.dropdown-menu a, 
.dropdown-menu button {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  color: #000;
  text-decoration: none;
}
  
.dropdown-menu a:last-child, 
.dropdown-menu button:last-child {
  border-bottom: none;
}

.dropdown-menu button {
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-menu a:hover,
.dropdown-menu button:hover {
  background-color: #dddddd;
}
  
  /* Top-right buttons styles */
  .top-right-buttons {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 20px;
    z-index: 1001;
  }
  
  .top-right-buttons button:hover {
    transform: scale(1.1);
    transition: all 0.2s ease;
    color: #5465ff;
  }
  
  /* Responsive adjustments with media queries */
  
  @media (max-width: 767px) {

    .logo-yg-container {
      display: flex; /* Use flexbox */
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
      width: 100px; /* Adjust size as needed */
      height: auto; /* Adjust size as needed */
    }

    .logo-yg {
      width: 100%; /* Ensure it fits within the container */
      height: auto; /* Maintain aspect ratio */
      /* max-width: 100%; Prevent overflow */
      background-size: contain; /* Contain within the container */
      background-repeat: no-repeat; /* No repeating */
    }
  
    .bm-burger-button {
      display: block;
    }
  
    nav,
    .login-register {
      display: none;
    }

    .header {
      flex-direction: column;
      align-items: flex-start;
      /* padding: 10px;*/
    } 
  
    .container {
      display: flex;
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
      flex-direction: column; /* Stack items vertically if needed */
    }
  }

  /* @media (min-width: 769px) {
    .bm-burger-bars {
      background: #182125;
    }

    .dropdown-menu {
      right: 0;
    }

    /* .logo-yg-container {
      align-content: center;
    } 
  } */


  /* Extra Small Devices, Phones (less than 415px) */
 /* @media (max-width: 415px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      /* padding: 10px;
    } 
  
    .container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .header__separator {
      display: none;
    }
  
    .logo-yg-container {
      width: 40px;
      height: 40px;
      position: relative;
    }
  
    .login-register button {
      font-size: 16px;
    }
  
    .top-right-buttons {
      position: static;
      padding: 0;
    }

    .button-login {
      margin-left: 5px;
    }
  }
  

  @media (min-width: 416px) and (max-width: 599px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      /* padding: 10px; 
    }
  
    .container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .header__separator {
      display: none;
    }
  
    .logo-yg-container {
      width: 45px;
      height: 45px;
      position: relative;
    }
  
    .login-register button {
      font-size: 17px;
    }
  
    .top-right-buttons {
      position: static;
      padding: 0;
    }

    .button-login {
      margin-left: 10px;
    }
  }

  @media (min-width: 600px) and (max-width: 767px) {
    /* .header {
      padding: 10px 15px;
    } 
  
    .container {
      justify-content: space-between;
    }
  
    .logo-yg-container {
      width: 50px;
      height: 50px;
      position: relative;
    }
  
    .login-register button {
      font-size: 18px;
    }
  }
  */
  /* Large Devices, Tablets (768px and up) */
  @media (min-width: 768px) and (max-width: 1199px) {
    /* .header {
      padding: 15px 30px;
    } */
    .logo-yg-container {
      width: 55px;
      height: 55px;
      position: relative;
      /* align-content: center; */
    }
  
    .login-register button {
      font-size: 18px;
    }
  }
  
  /* Extra Large Devices, Desktops (1200px and up) */
  @media (min-width: 1200px) and (max-width: 1439px) {
    /* .header {
      padding: 20px 40px;
    } */    
    .logo-yg-container {
      width: 60px;
      height: 60px;
      position: relative;
      /* align-content: center; */
    }
  
    .login-register button {
      font-size: 18px;
    }
  }
  