.modal {
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}

.titleContainer {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.modalTitle {
    font-size: 24px;
    margin-bottom: 0px;
}

.modalText {
    font-size: 16px;
    margin-bottom: 10px;
}

.modalInput {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 4px;
}

.modalInput:focus{
    border-color: #596ffc;
    /* Change border color on focus */
    outline: none;
    /* Remove default outline */
}

.modalActions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.cancelButton {
    background: #ccc;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


.cancelButton:hover {
    background-color: #000;
    color: #fff;
}

.deleteButton {
    background: #ff4d4f;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.deleteButton:disabled {
    background: #cccccc; /* Disabled background */
    color: #666666; /* Disabled text color */
    cursor: not-allowed;
    opacity: 0.6; /* Visual feedback for disabled state */
}

.deleteButton:not(:disabled) {
    transform: scale(1); /* Normal size */
}

.deleteButton:not(:disabled):hover {
    background: #e60000; /* Hover background for enabled state */
    transform: scale(1.05);
}