.detailsContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    color: #182125;
    max-width: 40rem;
    margin-top: 20px;
    box-shadow: inset 0 0 8px 2px #182125;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;
    padding-top: 30px;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #ccc;
}

.modalBody {
    overflow-y: auto;
    padding: 20px;
    height: calc(100% - 60px);
}

.detailsH2 {
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 1.2rem;
}

.contentContainer {
    display: flex;
    flex-direction: column;
}

.iconDisplay {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.itemDisplay {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 15px; 
}

.buttonContainer {
    display: flex;
    justify-content: flex-end; /* Alignement du bouton à droite */
}

.iconImage {
    margin-right: 5px;
    margin-left: 10px;
    width: 30px;
}

.moreDetailsButton {
    align-self: flex-end;
    padding: 8px 16px;
    border: 1px solid #182125;
    border-radius: 4px;
    background-color: #ffffff;
    color: #182125;
    cursor: pointer;
    font-size: 14px;
}

.moreDetailsButton:hover {
    background-color: #182125;
    color: #ffffff;
}

.closeButton {
    position: absolute;
    top: 23px;
    right: 28px;
    background: none;
    border: none;
    cursor: pointer;
    color: red; /* La couleur de l'icône */
    font-size: 24px; /* La taille de l'icône */
    z-index: 9999;
}

.divider {
    height: 1px;
    background-color: #ccc;
    margin: 30px 0;
}

.modalIcon {
    width: 50px;
    margin-right: 10px;
}

.modalTitle {
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 1.8rem;
}

.modalH2 {
    font-weight: 600;
    margin-bottom: 30px;
    font-size: 1.5rem;
}