/* NavDot.module.css */

.dot {
    width: 1rem; /* Taille du Dot */
    height: 1rem; /* Taille du Dot */
    border: 2px solid black; /* Bordure du Dot */
    border-radius: 50%; /* Rendre le Dot parfaitement rond */
    cursor: pointer; /* Curseur en forme de main pour indiquer la cliquabilité */
    position: relative; /* Position relative pour positionner correctement les enfants absolus */
    margin-bottom: 1rem; /* Marge en bas */
    margin-right: 1.5rem; /* Marge à droite */
  }
  
  .dot:hover {
    background-color: black; /* Couleur de fond lors du survol */
  }
  
  .label {
    position: absolute; /* Position absolue pour placer le label par rapport au Dot */
    right: 0;
    bottom: 100%; /* Positionner le label au-dessus du Dot */
    transform: translateY(-30%);
    margin-left: 0.5rem; /* Marge à gauche */
    width: 8rem; /* Largeur du label */
    text-align: center; /* Alignement du texte */
    background: linear-gradient(to right, #0F48FB, #5A0FFB); /* Dégradé de fond */
    color: white; /* Couleur du texte */
    padding: 0.5rem; /* Padding autour du texte */
    border-radius: 10px; /* Coins arrondis */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Ombre portée */
    z-index: 99999999; /* S'assurer que le label est au-dessus des autres éléments */
  }
  
  /* Ajouter d'autres styles personnalisés au besoin */
  