.buttonS {
    background-color: #2d004b;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin-top: 1vh;
  }

.buttonS:hover {
    background-color: #5465ff;
    color: #ffffff;
}
