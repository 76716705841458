.container {
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #ff214e;
}

.hrefColor {
    color: #5465ff;
    text-decoration: underline ;
    cursor: pointer;
}