@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
} */

.body {
  background: #ffffff;
}

.homepage {
  scroll-snap-type: y mandatory;
  /* autres styles ici */
}

.icon:hover {
    transform: scale(1.1);
    transition: transform 0.3s;
    color:rgb(4, 0, 255);
    opacity:  0.7;
  }

.service-icon {
    width: 20%;
    height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.header-link:hover {
  font-weight: bold;
}

.custom-section {
  /* Ajustez cette valeur en fonction de la hauteur de la section intro */
  scroll-snap-align: start;
  @media screen and (min-width: 2560px) {
    margin-top: 2rem;
    margin-bottom: 12rem;
    font-size: 110%; 
  }
}



  /* @media screen and (max-width: 1920px) and (min-width: 1001px) {
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-size: 80%; /* Taille de police pour l'écran large 
  }
  
  @media screen and (max-width: 1000px) and (min-width: 700px) {
    margin-top: -6rem;
    font-size: 50%; /* Taille de police pour les écrans moyens 
  }
  
  @media screen and (max-width: 699px) and (min-width: 520px) {
    margin-top: -4rem;
    font-size: 40%; /* Taille de police pour les petits écrans et les grands écrans 
  }

}

/* Styles spécifiques aux icônes 
.custom-section .service-icon {
  
  @media screen and (min-width: 2560px) {
      width: 20% !important;
  }

  @media screen and (max-width: 1920px) and (min-width: 1001px) {
    .service-icon {
      width: 30% !important;
    }
  }
  
  @media screen and (max-width: 1000px) and (min-width: 700px) {
      width: 20% !important;
  }
  
  @media screen and (max-width: 699px) and (min-width: 520px) {

      width: 10% !important;
     /* Largeur de l'icône pour les petits écrans et les grands écrans 
  }
}

/* Styles spécifiques aux images 
.custom-section img {

  @media screen and (min-width: 2560px) {
    width: 200%;
  }

  @media screen and (max-width: 1920px) and (min-width: 1001px) {
    width: 100%; /* Largeur de l'image pour l'écran large 
  }
  
  @media screen and (max-width: 1000px) and (min-width: 700px) {
    width: 80%; /* Largeur de l'image pour les écrans moyens 
  }
  
  @media screen and (max-width: 699px) and (min-width: 520px) {
    width: 60%; /* Largeur de l'image pour les petits écrans et les grands écrans 
  }
}  */

@media screen and (max-width: 1921px) and (min-width: 1001px) {
  .custom-quill-container .ql-container {
    height: 250px;
    max-height: 250px;
    overflow-y: auto; /* Manage scrolling within the editor itself */
  }
}


.roboto {
  font-family: 'Roboto', sans-serif;
}

.rotate {
  animation: rotation 2s infinite linear;
}

.iconZoom {
  transition: transform 0.3s ease-in-out;
}

.iconZoom:hover {
  transform: scale(1.1);
}

.label-spacing {
  margin-top: 1rem;
}

.file-name-box {
  border: 2px solid #ccc;
  padding: 5px;
  border-radius: 4px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

