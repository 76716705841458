@media screen and (max-width: 1921px) and (min-width: 1001px) {
    .titreh2 {
        font-size: 1rem !important;
        font-weight: 600;
    }

    .infop {
        font-size: .75rem ;
        font-style: italic;
        font-weight: 200;
    }

    .dateSelectorInput {
        padding: 5px 10px !important;
        border-radius: 8px;
        width: 80%;
        /* padding: 10px; */
        border: 1px solid #ccc;
        outline: none;
        font-size: .75rem !important;
        margin-bottom: 20px !important;
        margin-top: 10px !important;
    }

    .logo {
        /* Ajustez selon la taille réelle de votre logo */
        width: 100px !important;
        height: auto;
        display: inline-block;
        margin-bottom: 20px;
    }

    p {
        font-size: .75rem;
    }

    .profileInput {
        margin-bottom: 2px !important;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: .85rem !important;
      }

      .buttonStripe {
        background-color: #2d004b; 
        color: #ffffff; 
        font-weight: bold;
        padding: 8px 16px;
        border-radius: 4px;
        outline: none;
        box-shadow: 0 0 0 0 transparent;
        transition: background-color 0.2s, box-shadow 0.2s; 
        margin-top: 20px;
        font-size: .85rem !important;
    }

}

.stripePage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
}

.container {
    max-width: 960px;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;

    align-items: center;
}

.content {
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50vh;
}

.logo {
    /* Ajustez selon la taille réelle de votre logo */
    width: 150px;
    height: auto;
    display: inline-block;
    margin-bottom: 30px;
}

.titreh2 {
    font-size: 1.2rem;
    font-weight: 600;
}

.infop {
    font-size: 1rem;
    font-style: italic;
    font-weight: 200;
}

.buttonStripe {
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
    margin-top: 20px;
}

.buttonStripe:hover {
    background-color: #5465ff;    /* hover:bg-blue-700 */
}

.buttonStripe.inactive {
    cursor: not-allowed;
    opacity: 0.5;  
    pointer-events: none; 
}

.birthContainer {
    width: 95%;
}

.buttonStripe:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);    /* focus:shadow-outline */
}

.birthLabel {
    /* margin-right: 10px; */
    margin-top: 2vh;
    display: flex;
    text-align: left;
    flex-direction: column;
}

.dateSelectorInput {
    padding: 10px 20px;
    border-radius: 8px;
    width: 80%;
    /* padding: 10px; */
    border: 1px solid #ccc;
    outline: none;
    font-size: 1rem;
    margin-bottom: 25px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    flex-basis: 48%; /* Ajuste la largeur pour chaque groupe d'entrée, en laissant un peu d'espace entre eux */
}

.profileInput {
    margin-bottom: 20px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.hrefColor {
    color: #5465ff;
    text-decoration: underline ;
    cursor: pointer;
}