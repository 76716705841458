.device-notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    text-align: center;
    z-index: 1000;
  }
  
  .close-button {
    background: none;
    border: none;
    color: #721c24;
    font-weight: bold;
    cursor: pointer;
  }
  