.venueFormContainer {
    width: 100%;
    max-width: 40rem;
    align-content: center;
    text-align: start;
}

.noDataContainer {
    display: flex;
    align-content: center;
    justify-content: center;
}

.venueForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.venueBuildingRoom {
    width: 100%;
    margin-bottom: 10px;
}

.venueForm label {
    margin-bottom: 10px;
}

.venueForm input,
.venueForm textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.53333;
    transition: border-color 0.3s ease;
    /* Smooth transition */
    margin-bottom: 10px
}

.venueForm input::placeholder {
    color: #cccccc;
}

.venueForm input[disabled] {
    background-color: #f0f0f0; /* Light grey background */
    color: #999; /* Greyed-out text */
    cursor: not-allowed; /* Change cursor to indicate non-editable */
}

.venueForm input:focus,
.venueForm textarea:focus {
    border-color: #596ffc;
    /* Change border color on focus */
    outline: none;
    /* Remove default outline */
}

.venueFormTitle {
    font-size: 1.5rem;
    font-weight: 600;
}

.venueFormSubTitle {
    font-size: 0.895rem;
    font-style: italic;
    color: gray
}

.subCategory {
    padding-left: 15px;
    width: 100%;
    max-width: 40rem;
    align-content: center;
    text-align: start;
    border-left: 1px solid #ccc;
    outline: none;
    margin-bottom: 10px;
}

.subCategory>label {
    margin-top: 5px;
    margin-bottom: 10px;
    display: block;
}

.openModalButton {
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.openModalButton:hover {
    background-color: #b6b6b6;
    border: 1px solid #969696;
}

.selectedItems {
    margin-top: 10px;
    
}

.selectedItem {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.selectedItem:hover {
    background-color: #5363a796;
    border: 1px solid #5363a7;
}

.chevronIcon {
    margin-right: 10px;
}

.selectedIcon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.selectedSpan {
    margin-right: 5px;
}

.itemDetails {
    display: flex;
    align-items: center;
}

.iconContainer {
    align-items: center;
    display: flex;
    padding: 0 10px;
}

.deleteButton {
    color: #824b4b;
}

.deleteButton:hover {
    color: red
}

.verticalLine {
    width: 1px; 
    height: 20px; 
    background-color: #cccccc; 
    margin: 0 9px; 
}

.selectedItemsContainer {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(74, 1, 211, 0.5);
    }
    50% {
        box-shadow: 0 0 20px 10px rgba(74, 1, 211, 0.3);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(74, 1, 211, 0);
    }
}

.buttonSave {
    position: fixed;
    bottom: 2svw;
    right: 25svw;
    background-color: #2d004b;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;    /* Transition pour hover et focus */
}

.buttonSave:hover {
    background-color: #5465ff;
}

.buttonSave:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
}
.buttonSave.pulse {
    animation: pulse 1.5s infinite;
}

.buttonDelete {
    position: fixed;
    bottom: 2svw;
    right: 21svw;
    background-color: #ff4d4f;    /* bg-blue-500 */
    color: #ffffff;    /* text-white */
    font-weight: bold;    /* font-bold */
    padding: 8px 16px;    /* py-2 px-4 */
    border-radius: 4px;    /* rounded */
    outline: none;    /* focus:outline-none */
    box-shadow: 0 0 0 0 transparent;    /* Initialiser pour focus */
    transition: background-color 0.2s, box-shadow 0.2s;
    font-size: 1.5rem;
}

.buttonDelete:hover {
    background-color: #e60000;
    /* hover:bg-blue-700 */
}

.buttonDelete:focus {
    box-shadow: 0 0 0 3px rgba(136, 0, 0, 0.575);
    /* focus:shadow-outline */
}

.WifiToggleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.toggleCheckbox {
    display: none;
}

.toggleCheckboxWifi {
    display: none;
}

.toggleSwitch {
    width: 50px;
    height: 25px;
    background: #2d004b;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background 0.3s ease;
}

.toggleCheckbox:checked+.toggleSwitch {
    background: rgb(99, 102, 241);
}

.toggleCheckboxWifi:checked+.toggleSwitch {
    background: #3a46b1 ;
}

.toggleSwitch::after {
    content: '';
    width: 23px;
    height: 23px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: transform 0.3s ease;
}

.toggleCheckbox:checked+.toggleSwitch::after {
    transform: translateX(25px);
}

.toggleCheckboxWifi:checked+.toggleSwitch::after {
    transform: translateX(25px);
}

.toggleSwitch:hover {
    filter: brightness(1.1);
    transition: filter 0.3s ease;
}

.toggleCheckboxWifi:checked + .toggleSwitch:hover {
    filter: brightness(1.2);
}


@media (max-width: 415px) {
    .buttonDelete {
        right: 3svw;
    }

  }
  
  @media (min-width: 416px) and (max-width: 599px) {
    .buttonDelete {
        right: 3svw;
    }
   
  }
  
  @media (min-width: 600px) and (max-width: 767px) {

    .buttonDelete {
        right: 3svw;
    }

  }
  
  @media (min-width: 768px) and (max-width: 1199px) {
    .buttonDelete {
        right: 3svw;
    }

    .buttonSave {
        right: 10svw;
    }

  }
  
  @media (min-width: 1200px) and (max-width: 1974px) {

    .buttonDelete {
        right: 15svw;
    }

    .buttonSave {
        right: 20svw;
    }

  }
  
  @media (min-width: 1975px) and (max-width: 2559px) {


  }