@media screen and (max-width: 1921px) and (min-width: 1001px) {
    .iconForNightH1 {
        margin-left: 15px;
    }
}


.iconForNight {
    margin-top: 5px;
}  

.iconForNightH1 {
    margin-left: 20px;
}

.iconForNight span {
    margin-left: 15px; /* Ajuste l'espace entre l'icône et le texte */
}

/* .amountSpacing {
    margin-bottom: 16px; /* Ajuste 16px selon tes besoins
} */

.iconSize {
    width: 70px;
    height: auto;
    margin-top: 16px;
}

@media (min-width: 416px) and (max-width: 599px) {
    .iconSize {
        width: 45px;
        margin-top: 5px;
    }

}

@media (min-width: 600px) and (max-width: 767px) {
    .iconSize {
        width: 70px;
        height: auto;
        margin-top: 16px;
    }

}

@media (min-width: 768px) and (max-width: 1199px) {
    .iconSize {
        width: 70px;
        height: auto;
        margin-top: 16px;
    }

}

@media (min-width: 1200px) and (max-width: 1974px) {
    .iconSize {
        width: 70px;
        height: auto;
        margin-top: 16px;
    }

}

@media (min-width: 1975px) and (max-width: 2559px) {
    .iconSize {
        width: 70px;
        height: auto;
        margin-top: 16px;
    }

}