.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* Fond sombre semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050; /* Assurez-vous qu'il est au-dessus des autres éléments */
  }

.modalContent {
    border-radius: 8px;
    max-width: 80%;   
    max-height: 80%;
    overflow: auto;
}

.modalContent iframe {
    width: 90vw; /* 90% de la largeur de la fenêtre */
    height: 80vh; /* 80% de la hauteur de la fenêtre */
    max-width: 1080px; /* Largeur maximale */
    max-height: 630px; /* Hauteur maximale */
}

.closeButton {
    position: absolute;
    top: -30px; /* Ajustez selon la taille de votre icône */
    right: -20px; /* Ajustez en fonction de la taille de votre icône */
    background: none;
    border: none;
    cursor: pointer;
    color: white; /* Couleur de l'icône pour une meilleure visibilité sur le fond sombre */
    font-size: 24px; /* Taille de l'icône */
}

