@media screen and (max-width: 1921px) and (min-width: 1001px) {
    .custContainer {
        grid-template-columns: 1fr 0.5fr 2fr 0.5fr;
        /* Plus d'espace pour le contenu principal */
    }

    .sidebarCustBook,
    .mainContent {
        padding: 10px;/* Ajustement de l'espacement */
    }

    .backgroundImage {
        width: 30%;/* Ajustement de la taille de l'image de fond */
    }

    .sidebarSection {
        margin-left: 85px !important;
    }

    h1 {
        font-size: 1.8rem;/* Ajustement de la taille des titres */
    }

    h2 {
        font-size: 1rem;/* Ajustement de la taille des titres */
    }

    h3 {
        font-size: 0.8rem;/* Ajustement de la taille des titres */
    }

    h4 {
        font-size: 0.7rem; /* Ajustement de la taille des titres */
      }

    p {
        font-size: 0.7rem;
        /* Ajustement de la taille des titres */
    }
}

.custContainer {
    display: grid;
    grid-template-columns: 1fr 1px 2fr 1fr;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    position: relative;
    padding-bottom: 85px;
    /* scroll-snap-type: y mandatory;
    overflow-y: scroll; */
}

.pText {
    font-size: 14px;
}

.introText {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 50px;
}

.titleIntroText {
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 50px;
}

.introP {
    text-align: left;
}

.h1Header {
    margin-left: 50px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 2rem;/* Ajustement de la taille des titres */
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.mt20 {
    margin-top: 20px;
}

.bookingSection {
    scroll-snap-align: start;
    height: calc(100vh - 50px);
    /* height: 100vh; */
}

.sidebarCustBook {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1;
    padding: 20px;
    margin-left: 0px;
    align-self: center;
    z-index: 10;
}

.mainContent {
    display: grid;
    grid-template-rows: auto 200px;
    z-index: 15;
}

.sidebarSection {
    display: flex;
    flex-direction: column;
    margin-left: 75px;
}

.sidebarSectionH2 {
    cursor: pointer;
}

.sidebarDescription {
    margin-top: 3px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    /* font-size: 13px; */
    font-style: italic;
    z-index: 10;
}

.sidebarDescriptionOpen {
    max-height: 200px;
    border-bottom: 1px solid #5465ff;
    border-left: 1px solid #5465ff;
    border-right: 1px solid #5465ff;
    padding: 10px;
    margin-bottom: 10px;
}

.backgroundImage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 20%;
    z-index: 0;
    /* pointer-events: none; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider {
    /* background-color: #212866; */
    width: 1px;
    height: 70%;
    border-left: 1px dotted #2d004b;
}

.horizontalDivider {
    height: 1px;
    width: 70%;
    /* background-color: #212866; 
     */
    border-top: 1px dotted #2d004b;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20%;
}

.rightArrowIcon {
    transition: transform 0.3s;
    color: #2d004b;
    z-index: 10;
    font-size: 4em;
}

.rightArrowIcon:hover {
    transform: scale(1.1);
    color: rgb(4, 0, 255);
    /*#5465ff*/
    opacity: 0.7;
}