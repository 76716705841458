.modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    justify-content: center;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 800px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleModal {
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.buttonValidate {
    background-color: #212866;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin-top: 30px;
}

.buttonValidate:hover {
    background-color: #5465ff;
}

.bubuttonValidatettons:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
}

.buttonCancel {
    background-color: #ff0000;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin-top: 5px;
}

.buttonCancel:hover {
    background-color: #5465ff;
}

.buttonCancel:focus {
    box-shadow: 0 0 0 3px rgba(211, 61, 1, 0.877);
}

