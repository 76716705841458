@media screen and (max-width: 1921px) and (min-width: 1001px) {
  .sankeyChartContainer {
    /* flex: 1; */
    margin: 10px;
    height: 260px !important; /* ou la hauteur désirée */
    width: 100%;
    max-width: 950px !important; /* ou la largeur désirée */
  }
}


.sankeyChartContainer {
    /* flex: 1; */
    margin: 10px;
    height: 300px; /* ou la hauteur désirée */
    width: 100%;
    max-width: 1260px; /* ou la largeur désirée */
  }