.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.PageContainer {
  display: flex;
  min-height: 100svh;
  justify-content: center;
  padding-top: 100px;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;;
    height: 100vh; 
    background: linear-gradient(50deg,  #5767b0, #182125);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 150px 20px 20px;
    z-index: 19; 
    overflow-y: auto; /* Allow scrolling if content exceeds height */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebarSection {
  display: flex;
}
footer {
  position: relative; /* Give the footer a stacking context */
  z-index: 20; /* Ensure it stacks above the sidebar */
}

.collapsed {
  width: 100px;
  align-items: center;
}

.sidebar.smallScreen {
  width: 60px; /* Minimal width for just the toggle button */
  padding: 150px 20px 20px; /* Remove extra padding */
}

.sidebar.smallScreen .logoContainer,
.sidebar.smallScreen .sidebarSection {
  display: none; /* Hide sections on small screens */
}

.sidebarButton {
  background: none;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 1rem;
  font-weight: 600;
}

.toggleButton {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  margin: 10px 0;
  align-self: flex-end;
}

.sidebarButtonActive {
  font-weight: bold; /* Make text bold when active */
  border-bottom: 1px solid;
}

.toggleButtonCollapse {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  margin: 10px 0;
  align-self: center;
}

.contentContainer {
  display: flex;
  flex: 1;
  padding: 5svh;
  overflow-y: auto;
  justify-content: center;
}

.iconPanel {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 5px;
}

.iconChevPlus {
  margin-left: 5px;
}

.itemList {
  padding: 0.5svw 1svw;
  border-bottom: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-radius: 10px;
}

.item {
  font-size: 0.9rem;
  color: white;
  margin-bottom: 0.5svh;
  cursor: pointer;
}

.item:hover {
  text-decoration: underline;
}

.itemActive {
  font-size: 0.9rem;
  color: white;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: .5svh;
}

.noDataMessage {
  font-size: 0.895rem;
  color: orange;
  padding-left: 10px;
}

.logoContainer {
  margin-top: auto; /* Push the logo to the bottom */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 10px 0; /* Add some padding for spacing */
  position: relative;
}

.logo {
  max-width: 100%; /* Scale the logo to fit nicely */
  max-height: 65%; /* Ensure the logo doesn't overflow */
  object-fit: contain; /* Keep the aspect ratio */
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: transparent; /* No background */
  text-align: center;
}

.image {
  width: 200px;
  height: auto;
  margin-bottom: 1.5rem;
}

.text {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #333; /* Neutral dark text */
}

.createButton {
  background-color: #5465ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 15px;
  font-size: 1rem;
  transition: background 0.3s;
}

.createButton:hover {
  background-color: #4050cc;
}

@media (max-width: 768px) {
  .contentContainer {
    padding: 10svh;
  }

  .sidebar {
    width: 60px; /* Minimal sidebar width for mobile */
}

.collapsed {
    width: 60px; /* Adjust collapsed state for small screens */
}

}

@media (min-width: 768px) and (max-width: 1199px) {
  .contentContainer {
    padding: 10svh;
  }

}

@media (min-width: 1200px) and (max-width: 1974px) {



}

@media (min-width: 1975px) and (max-width: 2559px) {


}