/* Privacy.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centre le sous-conteneur */
    min-height: 100vh;
    background-color: #fff; /* Assure un fond blanc pour tout le contenu */
    padding-bottom: 50px;
}

.subContainer {
    margin-top: 10vh;
    width: 60%; /* Largeur à ajuster selon préférence pour contrôler la longueur des lignes */
    max-width: 900px; /* Largeur maximale */
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Optionnel: ajoute une ombre pour mieux distinguer le conteneur */
    background-color: #f9f9f9; /* Fond légèrement différent pour le sous-conteneur */
    border-radius: 8px; /* Bordures arrondies */
}

.title {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 10px;
    text-align: center
}

.subTitle {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center
}


.dateUpdate {
    font-style: italic ;
    margin-bottom: 20px;
    text-align: justify;
}

.boldtext {
    font-weight: bold;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 10px;
}


.textUl {
    list-style-type: disc; /* Ajout de bullets */
    margin-left: 20px; /* Alignement des bullets */
}

.subList {
    list-style-type: circle; /* Ronds vides pour les sous-listes */
    margin-left: 20px; /* Indentation des sous-listes */
}


.textLiP {
    margin: 5px 0; /* Espacement vertical entre les éléments de liste */
}

.hrefColor {
    color: #5465ff;
    text-decoration: underline ;
    cursor: pointer;
}

.buttonCookie {
    color: #5465ff;
    font-weight: 600;
}