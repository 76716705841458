.mainContainer {
    min-height: 100svh;
    /* padding-top: 20vh; */
}

.bookedRoomP {
    font-style: italic;
}

.bookedRoomH1 {
    color: #fff;
    font-weight: bold;
    font-size: 48px;
    margin-left: 20px;
    text-align: center; /* Center the heading */
}

.bookedRoomH2 {
    color: #182125;
    font-weight: bold;
    font-size: 36px;
    margin-top: 40px;
    text-align: center;
}

.bookedRoomH3 {
    color: #182125;
    font-weight: bold;
    font-size: 26px;
    margin-left: 20px;
    text-align: center;
}

.roomCardImageContainerModal {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
    border-radius: 5px;
}

.roomCardImageContainerModalCarousel {
    position: relative;
    width: 100%;
    max-width: 700px; /* Set a maximum width */
    max-height: 500px; /* Set a maximum height */
    overflow: hidden;
    border-radius: 5px;
    margin: 0 auto; /* Center horizontally */
    text-align: center; /* Center child elements */
}

.imageContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: center;
}

.roomCardCarouselImg {
    width: 100%; 
    height: 100%;
    object-fit: cover;
}

.roomCardNoCarousel {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.roomCardCarousel {
    width: 100%;
    height: auto;
}

/* Flexbox container for the row layout */
.detailsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
    padding: 5svh;
}

/* Flex items */
.detailsContainer > div {
    flex: 1;
    min-width: 300px; /* Ensures each item has a minimum width */
    margin: 10px;
    text-align: center; /* Center the content of each flex item */
    display: flex; /* Add flex display */
    flex-direction: column;
    justify-content: center; /* Center the contents horizontally */
    align-items: center; /* Center the contents vertically if needed */
}

.cancellationContainer {
    display: flex;
    flex-direction: column; /* Align items in a column */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    text-align: center; /* Center text within the container */
}

.buttonCancel {
    background-color: #ca0505;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin-top: 10px;
    margin-bottom: 30px;
}
  
.buttonCancel:hover {
    background-color: #f90909;
}
  
.buttonCancel:focus {
    box-shadow: 0 0 0 3px #7f0303;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.modalCancelTitle {
    font-size: 2rem;
    margin-bottom: 5svh;
}

.modalDescription {
    margin-bottom: 20px; /* Add space below the paragraph */
}

.modalList {
    list-style-type: disc; /* Use disc bullet points */
    padding-left: 20px; /* Adjust the padding to align bullets */
    text-align: left; /* Align text to the left for better readability */
    margin-bottom: 20px;
}

.modalList li {
    margin-bottom: 10px; /* Add some space between list items */
}

.buttonAction {
    background-color: #007BFF;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.buttonAction:hover {
    background-color: #0056b3;
}

.buttonAction:focus {
    box-shadow: 0 0 0 3px #0056b3;
}

.buttonIcon {
    margin-right: 8px; /* Space between icon and text */
}

.buttonChangeRoom {
    background-color: #007BFF;
}

.buttonChangeRoom:hover {
    background-color: #0056b3;
}

.buttonChangeRoom:focus {
    box-shadow: 0 0 0 3px #0056b3;
}

.buttonRefund {
    background-color: #28a745;
}

.buttonRefund:hover {
    background-color: #218838;
}

.buttonRefund:focus {
    box-shadow: 0 0 0 3px #1e7e34;
}

.buttonDonation {
    background-color: #ff0055;
}

.buttonDonation:hover {
    background-color: #c50243;
}

.buttonDonation:focus {
    box-shadow: 0 0 0 3px #990335;
}

.buttonClose {
    background-color: #6c757d;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin-top: 20px;
}

.buttonClose:hover {
    background-color: #5a6268;
}

.buttonClose:focus {
    box-shadow: 0 0 0 3px #545b62;
}

.changeRoomText {
    font-weight: bold;
    color: #007BFF;
}

.refundText {
    font-weight: bold;
    color: #28a745;
}

.donationText {
    font-weight: bold;
    color: #ff0055;
}

.congratsP {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}

.felicitations {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
}

@media (max-width: 415px) {
    .detailsContainer {
        flex-direction: column;
        align-items: center;
    }

    .bookedRoomH1 {
        font-size: 1.2rem;
        margin-left: 5px;
    }

    .bookedRoomH2 {
        font-size: 1.1rem;
        margin-top: 10px;
    }

    .bookedRoomH3 {
        font-size: 1rem;
        margin-left: 5px;
    }

    .roomCardImageContainerModalCarousel {
        max-width: 90vw;
    }
}

@media (min-width: 416px) and (max-width: 599px) {
    .detailsContainer {
        flex-direction: column;
        align-items: center;
    }

    .bookedRoomH1 {
        font-size: 1.2rem;
        margin-left: 5px;
    }

    .bookedRoomH2 {
        font-size: 1.1rem;
        margin-top: 10px;
    }

    .bookedRoomH3 {
        font-size: 1rem;
        margin-left: 5px;
    }

    .roomCardImageContainerModalCarousel {
        max-width: 80vw;
    }
}

@media (min-width: 600px) and (max-width: 767px) {
    .detailsContainer {
        flex-direction: column;
        align-items: center;
    }

    .bookedRoomH1 {
        font-size: 1.8rem;
        margin-left: 5px;
    }

    .bookedRoomH2 {
        font-size: 1.5rem;
        margin-top: 10px;
    }

    .bookedRoomH3 {
        font-size: 1.1rem;
        margin-left: 5px;
    }

    .roomCardImageContainerModalCarousel {
        max-width: 70vw;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .roomCardImageContainerModalCarousel {
        max-width: 60vw;
    }

    .venueContainer {
        flex-direction: column;
    }

    .venueInfo {
        padding: 50px; /* Adjust padding for medium screens */
    }

    .venuName {
        font-size: 32px; /* Adjust font size for medium screens */
    }
}

@media (min-width: 1200px) and (max-width: 1974px) {
    .roomCardImageContainerModalCarousel {
        max-width: 30vw;
    }

    .venueContainer {
        flex-direction: row;
    }

    .venueInfo {
        padding: 60px; /* Default padding */
    }

    .venuName {
        font-size: 36px; /* Default font size */
    }
}

@media (min-width: 1975px) {
    .venueContainer {
        flex-direction: row;
    }

    .venueInfo {
        padding: 60px; /* Default padding */
    }

    .venuName {
        font-size: 36px; /* Default font size */
    }

    .venueMap {
        margin-top: 0; /* No additional margin */
    }

    .resultatsChambresH1 {
        font-size: 28px;
    }

    .roomCard {
        max-width: 100%;
    }
}