.projectCard {
    border: 1px solid #ccc;
    padding: 16px;
    margin: 16px;
    border-radius: 8px;
    width: calc(700px + 30vw);
    overflow: hidden;
    transition: max-height 0.6s ease-in-out;
    min-height: 150px;  /* Ajustez selon vos besoins */
    position: relative;
    left: 5vw;
}

.projectCardButton {
    z-index: 10;
    position: relative;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid rgba(82, 82, 82, 0.74);
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-bottom: 20px;
}

.projectCardButton:hover {
    backdrop-filter: blur(5px);
    background-color: rgba(173, 216, 230, 0.3);
}

.projectCardExpanded {
    min-height: 550px;
    max-height: 1200px;
    margin-top: 20px;
}

.dashboardButton {
    position: absolute; /* Position absolue dans la carte */
    top: 10px; /* Ajustez ces valeurs selon vos besoins */
    right: 10px;
    z-index: 10; /* Assurez-vous qu'il est au-dessus des autres éléments */
    padding: 8px 16px;
    border: 1px solid #414141; /* Style de bordure */
    border-radius: 8px; /* Bords arrondis */
    cursor: pointer; /* Style du curseur */
}

.dashboardButton:hover {
    backdrop-filter: blur(5px);
    background-color: rgba(173, 216, 230, 0.3);
}

.dashboardButton.inactive {
    cursor: not-allowed;
    opacity: 0.5;  
    pointer-events: none; 
  }

.projectCard:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.backgroundImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.projectCardInput {
    z-index: 4;
    /* Assurez-vous que c'est supérieur aux autres z-index dans votre projet */
    position: relative;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    margin-bottom: 20px;
}

.projectCardTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    z-index: 1;
    position: relative; /* Ajouté */
}

.statusIcons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 5;
}

.statusIconsCollapsed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    right: 16px;
}

.statusIconsCollapsed .statusItem {
    margin-right: 32px;
  }

.statusIconsExpanded {
    flex-direction: column;
    align-items: flex-start;
}

.statusItem {
    margin-bottom: 8px;
}

.statusItemSpan {
    margin-left: 8px;
}
