@media screen and (max-width: 1921px) and (min-width: 1001px) {

    .guestGroupArea {
        min-height: 200px !important; 
        max-height: 330px !important;
        padding: 0,875rem !important;
    }

    .singleDropArea {
        min-height: 200px !important;
        max-height: 330px !important;
        padding: 0,875rem !important;
    }

    .dropArea {
        max-height: 200px !important;
        min-height: 150px !important;
        margin-bottom: 1px !important;
    }

}

.dragDropSection {
    display: flex;
    flex-direction: column;
    padding: 2px;
}

.dragDropSection h1,
.dragDropSection h2,
.dragDropSection p {
    text-align: center;
    margin: 0 auto; 
    width: 100%; 
}

.dragDropSection h1 {
    margin-bottom: 20px; 
    font-size: 1.5em; 
}

.dragDropSection h2 {
    font-size: 1em; 
    margin-bottom: 40px; 
    line-height: 1.4; 
    max-width: 80%; 
    margin-left: auto; 
    margin-right: auto; 
}

.dragDropSection p {
    font-style: italic;
}

.dragDropInterface {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 35px;
    width: 100%;
    margin-top: 10px;
    align-items: center;
}

.dropAreaContainer {
    display: flex;
    flex-direction: column;
}

.areaContainer {
    flex: 1;
    /* Pour permettre à chaque conteneur de prendre une part égale de l'espace disponible */
    display: flex;
    flex-direction: column;
    width: 100%;
    /* Pour positionner le titre au-dessus de la zone */
}

.singleDropArea {
    margin-bottom: 10px;
    border-radius: 10px;
    border: 2px solid rgba(219, 39, 39, 0.884);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.514);
    padding: 10px;
    width: 100%;
    margin-right: auto;
    margin-bottom: 10px;
}

.guestGroupArea {
    align-self: center;
    border: 2px solid #007bff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.514);
    overflow-y: auto;
    padding: 10px;
    min-height: 800px;
    max-height: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: 0;
}

.guestGroupArea, .singleDropArea {
    font-size: 0.875rem; /* Taille de la police relative à la taille de base */
    width: 70%; /* ou une valeur en % ou rem selon vos besoins */
    height: auto; /* ou une valeur en % ou rem selon vos besoins */
    padding: 0,875rem;
    /* autres styles */
}

.guestGroupAreaH3, .singleDropAreaH3 {
    font-size: 0,875rem; /* Taille de la police plus grande pour les titres */
}

.guestGroup {
    cursor: pointer;
    background-color: #f9f9f9;
    /* Couleur de fond claire */
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 10px;
    padding: 10px;
}

.guestGroupH3 {
    font-weight: bold;
}

.guestGroupLightColor:hover {
    filter: brightness(85%);
    /* Assombrir pour les couleurs claires */
}

.guestGroupDarkColor:hover {
    filter: brightness(115%);
    /* Éclaircir pour les couleurs foncées */
}

.dropArea {
    transition: background-color 0.3s ease;
    /* Style de bordure au choix */
    min-height: 750px;
    max-height: 754px;
    overflow-y: auto;
    /* Permet un défilement vertical si nécessaire */
    width: 100%;
    margin-bottom: 20px;
}


.dropAreaOver {
    background-color: #e0e0e0;
    /* Couleur lorsqu'un élément est survolé */
}

.dropArea:last-child {
    margin-right: 0;
}

.guestGroupDragging {
    opacity: 0.5;
    cursor: grabbing;
}

.sectionTitle {
    margin-bottom: 20px;
    /* Vous pouvez ajuster cette valeur selon vos préférences */
    font-weight: bold;
}

.dropAreaTitle {
    margin-bottom: 15px;
    font-weight: bold;
}

.dropAreaGroup {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9be;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.178);
}

.groupInfoH4 {
    font-weight: bold;
    margin-bottom: 2px;
}

.dropAreaGroupButton {
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.dropAreaGroupButton:hover {
    background-color: #ff7875;
}

.movedGroup {
    opacity: 0.5;
    /* Griser le groupe pour indiquer qu'il a été déplacé */
}

.centerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 5px;
    /* Ajustez en fonction de l'espace souhaité */
}

.iconContainer {
    display: flex;
    justify-content: center; /* Centrer l'icône horizontalement */
}

.flipIcon {
    transition: transform 0.5s ease; /* Durée et style de transition */
    display: inline-block; /* Nécessaire pour appliquer la transformation */
    margin-right: 10px;
}

.flipIcon:hover {
    transform: rotateY(180deg); /* Retourner l'icône */
}