.dropdown-container {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
}

.dropdown-custom {
  position: relative;
  cursor: pointer;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}

.dropdown-custom:focus-within {
  border-color: #596ffc; /* Change border color when dropdown or its children have focus */
  outline: none;
}

.dropdown-selected {
  /* margin: 2px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-label {
  margin: 10px 0;
}

.multidropdown-label {
  margin-top: 10px;
}

.dropdown-options {
  position: absolute;
  top: calc(100% + 5px); /* Push the dropdown options below the selected area */
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1;
  display: none; /* Initially hidden */
  overflow: scroll;
  max-height: 23rem;
}

.dropdown-custom:focus-within .dropdown-options {
  display: block;
}

.dropdown-item {
  /* padding: 10px; */
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 7px;
}

.dropdown-item-multi {
  /* padding: 10px; */
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 2px;
}

.dropdown-item:hover, 
.dropdown-item-multi:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
}

.dropdown-placeholder {
  color: #ccc
}

.dropdown-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.chevron-icon {
  color: #cfcfcf;
  transition: transform 0.2s;
}

.chevron-icon:hover {
  color: #999999;
}

.chevron-icon.open {
  transform: rotate(180deg); /* Rotate when dropdown is open */
}

.vertical-line {
  width: 1px; 
  height: 20px; 
  background-color: #cccccc; 
  margin: 0 9px; 
}