.spinner {
    border: 4px solid transparent; /* Bordure transparente */
    border-top-color: #09f; /* Couleur de départ pour le haut du spinner */
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite, color-change 2s linear infinite;
  }
  
  @keyframes spin {
    100% { transform: rotate(360deg); }
  }
  
  @keyframes color-change {
    0% { border-top-color: #09f; }
    25% { border-top-color: #f90; }
    50% { border-top-color: #9f0; }
    75% { border-top-color: #90f; }
    100% { border-top-color: #09f; }
  }

  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;  /* This will make the container take the full viewport height */
    width: 100vw;   /* This will make the container take the full viewport width */
}
