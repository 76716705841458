.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
  }
  
  .contactTitle {
    margin-top: 25vh;
    font-weight: 600;
    font-size: 2rem;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    gap: 16px;
  }
  
  .input, .textarea {
    width: 33%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .textarea {
    resize: vertical;
  }
  
  .dropzone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .error {
    color: #ff0000;
  }
  
  .success {
    color: #00ff00;
  }
  
  .buttonSoumettre {
    background-color: #2d004b;
    color: #ffffff;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 0 transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  
  .buttonSoumettre:hover {
    background-color: #5465ff;
  }
  
  .buttonSoumettre:focus {
    box-shadow: 0 0 0 3px rgba(74, 1, 211, 0.5);
  }
  
  @media (max-width: 415px) {
    .contactTitle {
      margin-top: 15vh;
    }
  
    .input, .textarea {
      width: 66%;
    }
  }
  
  @media (min-width: 416px) and (max-width: 599px) {
    .input, .textarea {
      width: 66%;
    }
  }
  
  @media (min-width: 600px) and (max-width: 767px) {
    .input, .textarea {
      width: 50%;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1199px) {
    .input, .textarea {
      width: 66%;
    }
  }
  
  @media (min-width: 1200px) and (max-width: 1974px) {
    .input, .textarea {
      width: 100%;
    }
    
    .scaled  .container {
    padding-bottom: 55rem;
    }
  }
  
  @media (min-width: 1975px) and (max-width: 2559px) {
    .input, .textarea {
        width: 100%;
    }
  }
  
  @media (min-width: 2560px) {
    .input, .textarea {
      width: 100%;
    }
  }
  