.countDown {
    display: flex;
    justify-content: center;
    font-family: sans-serif;
    text-align: center;
    padding-top: 75px;
    gap: 100px;
  }
  
.countDownTime {
font-size: 46px;
}

@media (max-width: 415px) {
  .countDown {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for the grid */
    gap: 30px;
    padding-top: 40px;
    justify-content: center; /* Centers the grid horizontally */
  }

  .countDownDiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content within each grid item */
    gap: 10px;
  }

  .countDownTime {
    font-size: 24px;
  }
}

@media (min-width: 416px) and (max-width: 599px) {
  .countDown {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for the grid */
    gap: 30px;
    padding-top: 40px;
    justify-content: center; /* Centers the grid horizontally */
  }

  .countDownDiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content within each grid item */
    gap: 10px;
  }

  .countDownTime {
    font-size: 28px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .countDown {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for the grid */
    gap: 30px;
    padding-top: 40px;
    justify-content: center; /* Centers the grid horizontally */
  }

  .countDownDiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content within each grid item */
    gap: 10px;
  }

  .countDownTime {
    font-size: 36px;
  }
}

@media (min-width: 768px) and (max-width: 824px) {
  .countDown {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns for the grid */
    gap: 30px;
    padding-top: 40px;
    justify-content: center; /* Centers the grid horizontally */
  }

  .countDownDiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content within each grid item */
    gap: 10px;
  }

  .countDownTime {
    font-size: 40px;
  }
}

@media (min-width: 825px) and (max-width: 1200px) {
  .countDown {
    display: flex;
    gap: 30px;
  }

  .countDownDiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content within each grid item */
    gap: 10px;
  }

  .countDownTime {
    font-size: 46px;
  }
}

@media (min-width: 1201px) and (max-width: 1974px) {
  .countDown {
    display: flex;
    gap: 100px;
  }

  .countDownDiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content within each grid item */
    gap: 10px;
  }

  .countDownTime {
    font-size: 46px;
  }
}

@media (min-width: 1975px) and (max-width: 2559px) {
  .countDown {
    display: flex;
    gap: 120px;
  }

  .countDownDiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content within each grid item */
    gap: 10px;
  }

  .countDownTime {
    font-size: 50px;
  }
}

@media (min-width: 2560px) {
  .countDown {
    display: flex;
    gap: 140px;
  }

  .countDownDiv {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content within each grid item */
    gap: 10px;
  }

  .countDownTime {
    font-size: 54px;
  }
}

