.scroll-to-top {
    position: fixed;
    right: 20px;  /* 20px du bord droit de la page */
    bottom: 20px;  /* 20px du bas de la page */
    cursor: pointer;
    color: #000;
    font-size: 2.5rem;  /* taille de l'icône */
    display: none;
  }
  
  .scroll-to-top:hover {
    color: #5A0FFB;
    transform: scale(1.1);
  }