@media screen and (max-width: 1921px) and (min-width: 1001px) {

  .sectionContainer {
    margin-top: 1150px;
  }

  .titleH3 {
      font-size: 0.9rem !important;
      margin-top: 10px !important;
  }

  .section {
    min-width: 850px !important;
    margin-bottom: 20px;
  }

}

.profileForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
.profileLabel {
  margin-right: 10px;
  margin-bottom: 5px;
}
  
.profileInput {
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modified {
  border-color: #5465ff; /* ou toute autre couleur pour indiquer une modification */
}
  
.profileButton {
  padding: 10px 20px;
  background-color: #2d004b;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 35px;
} 

.sectionContainer {
  margin-top: 200px;
}

.profileButton:hover {
  background-color: #5465ff;
} 
  
.section {
  margin-bottom: 40px;
  padding: 25px;
  border: 2px solid #5465ff; /* Épaisseur, style et couleur de la bordure */
  border-radius: 4px;
  min-width: 1200px;
}
  
.radioGroup label {
  margin-right: 10px;
}
  
.radioGroup input[type="radio"] {
  margin-right: 5px;
  margin-bottom: 15px;
}

.bankInfo {
  display: flex;
  align-items: flex-start;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboardTitleContainer {
  display: flex;
  justify-content: center;
}

.titleH3 {
  margin-bottom: 10px;
  margin-right: 5px;
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.buttonTitleContainer {
  margin-bottom: 10px;
  margin-top: 20px;
}

.customLaunchIcon {
  transition: transform 0.3s ease, color 0.3s ease;
}

.customLaunchIcon:hover {
  color: #5465ff; 
  transform: scale(1.2); 
}

.inputGroup {
  display: flex;
  flex-direction: column;
  flex-basis: 48%; /* Ajuste la largeur pour chaque groupe d'entrée, en laissant un peu d'espace entre eux */
}

.profileInput, .DatePicker {
  width: 100%; /* Assure que les entrées prennent toute la largeur de leur conteneur */
}

.invalidIban {
  border-color: red;
}

.dateSelectorInput {
  padding: 10px 20px;
  border-radius: 8px;
  /*border: 1px solid #ccc; */
  /* outline: none; */
  width: 60%;
  /* padding: 10px; */
  border: 1px solid #ccc;
  outline: none;
  font-size: 1rem;
  /* Ajustez la taille de police si nécessaire */
}

.returnButton {
  color: #2d004b;
}

.returnButton:hover {
  color: #5465ff;
  transform: scale(1.1);
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;  /* This will make the container take the full viewport height */
  width: 100vw;   /* This will make the container take the full viewport width */
}