.emailEditorContainer {
    /* place-items: center; */
    /* display: grid; */
    justify-content: center;
    min-width: 1200px;
    /* margin-top: 50px */
  }

.customTabContainer {
  text-align: right;
  background-color: #f3f3f3; /* Ajustez en fonction du thème de votre éditeur */
  border-bottom: 1px solid #d3d3d3; /* Ajustez en fonction du thème de votre éditeur */
}

.customSaveTab {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    /* Autres styles pour correspondre à l'apparence de vos onglets */
}